.tag {
   position: absolute;
   top: 0;
   left: 0.6875rem;
   border-radius: 0px 0px 0.25rem 0.25rem;
   padding: 0 0.5rem;
   font-size: 0.625rem;
   color: #fff;
   text-transform: uppercase;
   font-family: Prompt !important;
   font-weight: 500;

   &.epub {
      background-color: #639f00;
   }

   &.pdf {
      background-color: #df1125;
   }
}

.tagStyle {
   padding: 0.5rem 1rem;
   border-radius: 2rem;
   background-color: var(--grey6);
   font-size: 0.875rem;
   color: var(--grey2);
   transition: 0.3s;

   &:hover {
      background-color: var(--grey5);
      color: var(--grey2);
   }
}

.tagContainer {
   display: flex;
   align-items: center;
   gap: 1rem;
   flex-wrap: wrap;
}
