.voteEbookContainer {
   width: 100vw;
   margin: 0 auto;
   max-width: 1280px;
   min-height: calc(100vh - 150px);
   position: relative;
   margin-bottom: 2rem;

   @media (max-width: 1280.98px) {
      padding: 0 1rem;
   }

   h1 {
      font-size: 1.625rem;
      color: var(--grey1);
      margin-bottom: 2rem;
   }

   .contentContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 1rem;

      .catalogItem {
         display: flex;
         overflow: hidden;
         border-radius: 0.5rem;
         background: var(--grey6);
         height: 180px;

         .coverImage {
            display: flex;
            justify-content: center;
            width: 35%;
            padding: 1.5rem;

            img {
               width: auto;
               height: 100%;
            }
         }

         .catalogDetail {
            width: 65%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 0.5rem;
            padding: 1rem;

            h4 {
               color: var(--grey1);
               font-size: 1rem;
               margin-bottom: 0;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 2;
               overflow: hidden;
            }

            p {
               color: var(--grey1);
            }

            button {
               color: #fff;
               border: none;
               height: 2.5rem;

               &.isVoted {
                  background: var(--secondary);
               }

               &.isVote {
                  background: var(--hti-primary-dark);
               }
            }
         }
      }
   }
}

.catalogEbookContainer {
   width: 100vw;
   margin: 0 auto;
   max-width: 1280px;
   min-height: calc(100vh - 150px);
   position: relative;
   padding: 1rem;
   padding-bottom: 3rem;

   .titleSection {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 2rem;
      margin-bottom: 1.5rem;

      h1 {
         font-size: 1.625rem;
         color: var(--grey1);
         margin-bottom: 0.5rem;
         line-height: 1;
         text-align: left;
      }

      h4 {
         color: var(--secondary);
         margin-bottom: 0rem;
      }

      p {
         color: var(--grey1);
         font-weight: 500;

         span {
            color: var(--secondary) !important;
         }
      }
   }

   .contentContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 1rem;
      align-items: flex-end;

      .contentItem {
         .coverImage {
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));

            img {
               width: 100%;
               height: auto;
            }

            &:hover {
               cursor: pointer;
            }

            .floatItem {
               width: 101%;
               height: 101%;
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               gap: 0.5rem;
               background: #00000099;
               transition: 0.3s all;

               &.bounceIn {
                  svg {
                     animation: bounceIn 0.4s ease-in-out;
                  }
               }

               &.bounceOut {
                  opacity: 0;

                  svg {
                     animation: bounceOut 0.4s ease-in-out;
                  }
               }

               p {
                  margin-bottom: 0;
                  font-size: 1.25rem;
                  font-weight: 700;
                  color: #fff;
               }
            }
         }

         button {
            width: 100%;
            border: none;
            color: #fff;
            height: 2.5rem;
            transition: all 0.5s;

            &:disabled {
               color: var(--grey5);
               background: var(--grey6) !important;
               cursor: not-allowed;
            }

            &.vote {
               background: var(--primary);
            }

            &.unVote {
               background: var(--secondary);
            }
         }

         .detail {
            margin: 0.5rem 0;
            min-height: 50px;

            p {
               color: var(--hti-primary-light);
               line-height: 1.4;
               margin-bottom: 0;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 2;
               overflow: hidden;

               &:hover {
                  cursor: pointer;
               }

               &.authorName {
                  color: var(--grey2);
                  font-size: 0.75rem;
                  -webkit-line-clamp: 1;
               }
            }
         }
      }
   }

   .floatMenu {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4rem;
      padding: 0 1rem;
      background: var(--primary-dark);
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px -4px 12px;

      .flexContainer {
         display: flex;
         align-items: center;
         gap: 0.5rem;
      }

      &.fadeIn {
         animation: TabFadeIn 0.3s ease-in forwards;
      }

      &.fadeOut {
         animation: TabFadeOut 0.3s ease-in forwards;
      }

      svg {
         color: var(--surface);
      }

      button {
         background: #fff;
         color: var(--primary-dark);
         height: 2.5rem;
         width: 120px;
         border: none;

         &:disabled {
            color: var(--grey5);
            background: var(--grey6) !important;
            cursor: not-allowed;
         }

         svg {
            color: var(--primary) !important;
         }
      }

      p {
         font-size: 1.125rem;
         color: #fff;
         margin-bottom: 0;
      }
   }

   @keyframes TabFadeOut {
      from {
         transform: translateY(0);
      }
      to {
         transform: translateY(4rem);
      }
   }

   @keyframes TabFadeIn {
      from {
         transform: translateY(4rem);
      }
      to {
         transform: translateY(0);
      }
   }

   @keyframes bounceIn {
      0% {
         transform: scale(0);
      }
      40% {
         transform: scale(1.5);
      }
      100% {
         transform: scale(1);
      }
   }

   @keyframes bounceOut {
      0% {
         transform: scale(1);
      }
      100% {
         transform: scale(0);
      }
   }
}
