@import 'antd/dist/antd.min.css';

.autocomplete-header {
    font-family: Prompt;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
}

.autocomplete-item {
    font-family: Prompt;
    font-weight: 400;
    font-size: 12px;
    color: #6a6a6a;
}

.ant-select-dropdown {
    p {
        font-family: Prompt !important;
    }
}

.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 105px);

    button {
        background-color: var(--primary);
        color: #fff;
    }
}
