.detail {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.image-cover {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: all 0.3s;

    &:hover {
        cursor: pointer;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.number-tag {
    width: fit-content;
    background-color: #fce8ea;
    color: #df1125;
    border-radius: 1.5rem;
    padding: 0.125rem 0.875rem;
    font-size: 0.75rem;
    font-weight: 600;
}

.title-text {
    width: fit-content;
    font-size: clamp(0.75rem, 0.7197rem + 0.1294vw, 0.875rem);
    font-weight: 500;
    margin-bottom: 0;
    color: var(--header);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    line-height: 1.6;
}

.sub-text {
    width: fit-content;
    font-size: clamp(0.625rem, 0.5947rem + 0.1294vw, 0.75rem);
    font-weight: 400;
    margin-bottom: 0;
    color: var(--sub-header);
    line-height: 1.6;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.button-contain {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button {
        padding: 0 0.5rem !important;
    }
}

.content-cover {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
}

.contents-container {
    .swiper-wrapper {
        align-items: baseline;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0 !important;
    }

    &.swiper-contents-view {
        .swiper-wrapper {
            flex-wrap: wrap;
            flex-direction: row;
        }
    }
}

.content-with-rankTitle-container {
    display: grid;
    align-items: baseline;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;

    .swiper-wrapper {
        align-items: baseline;
    }
}

.content-list-with-background-container {
    .swiper-slide {
        height: unset;
    }

    .image-cover {
        max-width: 100px;
        max-height: calc(180px - 32px);
        transition: all 0.3s;
        cursor: pointer;
        max-height: 100%;

        &:hover {
            transform: scale(1.05);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .background-container {
        display: flex;
        align-items: flex-start;
        border-radius: 0.25rem;
        overflow: hidden;
        gap: 1rem;
        padding: 1rem;
        height: 180px;
    }

    .detail {
        justify-content: space-between;
        height: 100%;

        button {
            max-width: 120px;
        }
    }
}

.content-list-with-rank {
    display: flex;

    .rank-title {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--hti-secondary);
        line-height: 1;
    }

    .queue-title {
        margin-left: 2rem;

        > div:first-child {
            color: var(--header);
            font-weight: 500;
            font-size: 1rem;
        }

        > div:last-child {
            color: var(--hti-secondary);
            font-weight: 600;
            font-size: 1.25rem;
        }
    }

    .cover-container {
        display: flex;
        gap: 1rem;
        height: 180px;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .image-cover {
        width: 100%;
        max-width: 200px;
        height: fit-content;
        max-height: 100%;

        &:hover {
            transform: scale(1.05);
        }

        img {
            width: 100%;
            object-fit: fill;
        }
    }

    .detail {
        justify-content: space-between;
        height: 100%;

        button {
            max-width: 120px;
            margin-left: 1.5rem;
        }
    }
}

.content-list-segment {
    display: flex;

    .cover-container {
        display: flex;
        gap: 1rem;
        height: 180px;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .image-cover {
        width: 100%;
        max-width: 120px;
        height: fit-content;
        max-height: 100%;

        img {
            width: 100%;
            object-fit: fill;
        }
    }

    .detail {
        justify-content: space-between;
        height: 100%;

        button {
            max-width: 120px;
        }
    }
}

.content-list-with-cover-background {
    display: flex;

    .cover-container {
        display: flex;
        gap: 1rem;
    }

    .cover-box {
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 0.25rem;
    }

    .image-cover {
        width: fit-content;
        transition: all 0.3s;
        cursor: pointer;

        // &:hover {
        //     transform: scale(1.05);
        // }

        img {
            width: auto;
            height: auto;
            max-width: 200px;
            max-height: 170px;
            border-radius: 0.25rem;
        }
    }
}

.banner-square {
    img {
        width: 100%;
        height: auto;
        border-radius: 0.25rem;
        box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.03) translateY(-0.5rem);
        }
    }
}

.banner-rectangle {
    img {
        width: 100%;
        height: auto;
        border-radius: 0.25rem;
        box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transition: all 0.3s;
        max-height: 170px;

        &:hover {
            transform: scale(1.03) translateY(-0.5rem);
        }
    }
}

.sponsorContainer {
    .itemContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .coverImage {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        overflow: hidden;
        box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

        img {
            width: 100%;
            height: 100%;
            transition: all 0.3s;
            object-fit: cover;
        }

        &:hover {
            img {
                transform: scale(1.05);
                cursor: pointer;
            }
        }
    }

    p.sponsor-name {
        width: fit-content;
        font-size: clamp(0.75rem, 0.7197rem + 0.1294vw, 0.875rem);
        font-weight: 500;
        margin-bottom: 0;
        color: var(--sub-header);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
        line-height: 1.6;
    }
}

.sponsorBanner {
    .swiper-container {
        padding: 1rem 0;
        margin: -1rem 0;
    }

    .itemContainer {
        display: flex;
        justify-content: center;

        img {
            width: 100%;
            height: auto;
            border-radius: 0.25rem;
            box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.03) translateY(-0.5rem);
            }
        }
    }
}
