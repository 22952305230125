.segmentContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    .ant-segmented {
        background: #f2f2f2;

        .ant-segmented-group {
            padding: 0.25rem;

            .ant-segmented-item {
                display: flex;
                align-items: center;

                &.ant-segmented-item-selected {
                    .ant-segmented-item-label {
                        font-size: 1.125rem;
                        font-weight: 500;
                        color: var(--primary);
                    }
                }

                .ant-segmented-item-label {
                    font-size: 1.125rem;
                    color: #bdbdbd;
                }
            }
        }

        &:hover {
            background: #f2f2f2;
        }
    }

    .segment-button {
        border-radius: 4px;
        text-align: center;
        padding: 5px;
        margin: 0 auto 2rem auto;
        font-family: Prompt;
        display: inline-block;
        background-color: rgb(239, 239, 239);
        margin-top: 2rem;

        .mode-button-active {
            cursor: pointer;
            outline: none;
            border-radius: 4px;
            background-color: white;
            padding: 3px 20px;
            border: none;
            color: var(--primary);
            font-weight: 600;
            font-size: 18px;
            transition: all 0.5s;
        }

        .mode-button {
            cursor: pointer;
            outline: none;
            border-radius: 4px;
            padding: 3px 20px;
            border: none;
            color: #828282;
            font-weight: 400;
            font-size: 18px;
            transition: all 0.5s;
        }
    }
}
