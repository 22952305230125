$boxShadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
$borderRadius: 0.5rem;
$boxPadding: 1rem;

.form-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--grey6);
    min-height: 100vh;

    h1 {
        padding-top: 0 !important;
    }

    form {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
    }

    span {
        font-family: 'Prompt';
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: var(--primary);

        &:after {
            background-color: var(--primary);
        }
    }
}

.form-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 768px;
    margin: 0 auto;
    padding: 1.5rem;

    .image-cover {
        width: 100%;
        height: 200px;
        border-radius: $borderRadius;
        background-image: url('https://s3-ap-southeast-1.amazonaws.com/hti.elibrary/public/banners/fb6cc11d-26c9-47b3-b27a-75c875d2b93f.png');
        overflow: hidden;
        background-position: 20% 40%;
        box-shadow: $boxShadow;
    }

    .form-title {
        width: 100%;
        background-color: #fff;
        box-shadow: $boxShadow;
        border-radius: $borderRadius;
        padding: $boxPadding;

        > h1 {
            font-size: 2rem;
            font-weight: 500 !important;
            max-width: 50ch;
            text-align: left;
            line-height: 1.5;
        }

        > p {
            font-size: 1rem;
            margin-bottom: 0;
            font-weight: 400;
            color: var(--grey3) !important;
        }
    }

    .section-title {
        width: 100%;
        padding: $boxPadding;
        border-radius: $borderRadius;
        box-shadow: $boxShadow;
        background-color: #fff;

        > h1 {
            font-size: 1.5rem !important;
            font-weight: 500 !important;
            text-align: left;
            margin-bottom: 0;
            color: var(--primary);
        }
    }

    .radio-button-box {
        width: 100%;
        padding: $boxPadding;
        border-radius: $borderRadius;
        box-shadow: $boxShadow;
        background-color: #fff;

        .radio-button {
            padding-left: 0.5rem;
        }
    }

    .rating-box {
        width: 100%;
        padding: $boxPadding;
        border-radius: $borderRadius;
        box-shadow: $boxShadow;
        background-color: #fff;
        overflow: hidden;

        .ant-radio-group {
            width: 100%;
        }

        .ant-space {
            width: 100%;
            justify-content: space-around;
        }

        .ant-radio-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            max-height: 3.5rem;
        }
    }

    .ask-text {
        font-size: 1rem !important;
        font-weight: 400;
        margin-bottom: 1rem;
        color: black !important;
    }

    .check-box-container {
        width: 100%;
        padding: $borderRadius;
        border-radius: $borderRadius;
    }
}
