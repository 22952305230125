.component-loading {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    top: 0;
    .loading {
        width: 100px;
        height: 100px;
    }
}
.component-loading-absolute {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    .loading {
        width: 100px;
        height: 100px;
    }
}
.auth-modal-content {
    position: relative;
}
.in-component-loading {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: auto;
    .loading {
        width: 70px;
        height: 70px;
    }
}
