.qrCodeContainer {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 90vh;
   padding: 0 1rem;

   .contentContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.25rem;
      width: 100%;
      max-width: 500px;

      h1 {
         color: var(--grey1);
         margin-bottom: 0;
      }

      h4 {
         color: var(--grey1);
         margin-bottom: 0;
         text-align: center;
      }

      .qrCodeCover {
         width: 100%;
         max-width: 340px;
         border-radius: 0.625rem;
         border: 2px solid var(--grey5);
         overflow: hidden;
         padding: 0.5rem;

         img {
            width: 100%;
            height: auto;
         }
      }
   }
}
