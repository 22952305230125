@import '../../../../public/style/base';

h1 {
    color: var(--title);
}

.bookLayout,
.bookSwiper {
    &.collection {
        .swiper-slide,
        .ant-col {
            align-items: flex-end;
        }
    }

    &.list {
        .swiper-slide,
        .ant-col {
            height: calc(#{$list-cover-height} + #{$space2});
            width: 50%;

            @media (max-width: $max-width-sm) {
                width: 100%;
            }

            > div {
                width: 100%;
                display: flex;
                justify-content: left;
                margin-top: 16px;

                .cover {
                    margin: 0 0 #{$space} 0;
                    width: 120px;
                    height: auto;
                    overflow: hidden;
                    max-height: 170px;
                }

                .bookTitleContainer {
                    margin-left: $minspace;
                    width: calc(100% - 154px);
                }

                .detail {
                    margin-bottom: $minspace;
                }

                button {
                    @include setButtonSize(120px, 30px);
                    &.Rented {
                        background-color: $disable-button-color;
                        border-color: $disable-button-color;
                    }
                    &.Rent {
                        background-color: $title-color;
                        border-color: $title-color;
                    }
                    &.WaitingList {
                        background-color: $secondary-color;
                        border-color: $secondary-color;
                    }
                }
            }
        }

        &.border {
            .ant-col {
                > div {
                    border: $border;
                    border-radius: 10px;
                    margin-top: 0px;
                    padding: $minspace;
                    height: calc(#{$list-cover-height} + #{$space});
                }

                .bookTitleContainer,
                .cover {
                    margin-bottom: 0;
                }
            }
        }
        &.bestseller {
            .cover {
                width: 86px !important;
            }
            .title {
                @include setWordwrap(1);
            }
            .detail {
                @include setWordwrap(1);
            }
        }
    }

    .swiper-slide,
    .ant-col {
        border: $noborder;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        position: relative;

        > div {
            border: $noborder;

            .cover {
                margin: 0 auto #{$minspace};
            }

            .bookTitleContainer {
                width: 100%;
                position: relative;
                color: $title-color;
            }

            .title {
                width: 100%;
                @include setWordwrap(2);
                > * {
                    @include setHeader;
                }
            }

            .detail {
                width: 100%;
                @include setWordwrap(1);
            }
        }
    }

    .title {
        @include setHeaderBold(500);
        font-size: $font-head-size;
        color: $title-color;
        line-height: 1.2;
    }

    .detail {
        font-family: $font-body;
        font-size: $font-size;
        font-weight: 400;
        color: $grey2-color;
    }

    .cover {
        > img {
            width: auto;
            max-width: 100%;
            max-height: 220px;
        }
    }
    .coverQnH {
        margin: 0px 0px 16px !important;
        img {
            width: 108px;
            height: 100%;
        }
    }
    .titleNumber {
        @include setHeader;
        font-size: $font-size;
        color: $title-color;
    }
    .queueNumber {
        @include setHeader;
        font-size: 1.3em;
        font-weight: 600;
        color: $secondary-color;
        line-height: 1em;
        margin-bottom: $minspace;
    }

    .rankBestseller {
        @include setHeader;
        font-size: 36px;
        font-weight: 500 !important;
        color: $secondary-color;
        width: 52px;
        padding-left: $minspace;
        text-align: center;
        line-height: 32px;
    }

    &.bestseller {
        .title {
            @include setWordwrap(1);
        }
        .detail {
            @include setWordwrap(1);
        }
    }

    .noselect,
    .title,
    .detail,
    .filetype,
    .titleNumber,
    .queueNumber,
    .cover {
        @include setNoHighlight();
    }
}

.bookSwiper {
    @include setWidth;
    margin: 0 auto #{$space};

    .swiper-container {
        @media (max-width: 1376.98px) {
            padding: 0 1rem;
            margin: 0 -1rem;
        }
    }

    .swiper-wrapper {
        .swiper-slide {
            > div {
                margin: 0;
            }
        }
    }

    &:not(.video) {
        .swiper-wrapper {
            .swiper-slide {
                @media (min-width: 1281px) {
                    @include setHeightSlider(1280px, 8);
                }
                @media (max-width: 1280px) {
                    @include setHeightSlider(100vw, 8);
                }
                @media (max-width: 1024px) {
                    @include setHeightSlider(100vw, 6.5);
                }
                @media (max-width: 768px) {
                    @include setHeightSlider(100vw, 5.5);
                }
                @media (max-width: 540px) {
                    @include setHeightSlider(100vw, 3.5);
                }
                @media (max-width: 375px) {
                    @include setHeightSlider(375px, 3.5);
                }
            }
        }
    }

    &.bestseller {
        .swiper-container {
            height: 332px;

            @media (max-width: 1376.98px) {
                padding: 0 1rem;
                margin: 0 -1rem;
            }
        }
        .swiper-wrapper {
            flex-wrap: wrap;
            flex-direction: column;
            .swiper-slide {
                height: calc((100% - 32px) / 2);
            }
        }
    }
}

.categorySwiper {
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;

    .swiper-container {
        padding: 1rem;
        margin: -1rem;
    }

    .swiper-wrapper {
        align-items: baseline;

        .swiper-slide {
            margin-right: $space;

            > div {
                width: 100%;
            }
        }

        .category-swiper,
        .contentSet-swiper {
            .category {
                @media (min-width: 1281.98px) {
                    height: calc((1280px - (0px * 7)) / 6.2);
                }
                @media (max-width: 1280.98px) {
                    height: calc((100vw - (0px * 6)) / 5.2);
                }
                @media (max-width: 1024.98px) {
                    height: calc((100vw - (0px * 5)) / 4.2);
                }
                @media (max-width: 768.98px) {
                    height: calc((100vw - (0px * 4)) / 3.2);
                }
                @media (max-width: 576.98px) {
                    height: calc((100vw - (0px * 3)) / 2.2);
                }
                @media (max-width: 414.98px) {
                    height: calc((414px - (0px * 3)) / 2.2);
                }
            }

            .cover {
                overflow: hidden;
            }
            > div {
                margin: 0;
            }
        }
    }
}

.bookLayout {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;

    @media (max-width: 1280.98px) {
        grid-template-columns: repeat(7, 1fr);
        padding: 0 1rem;
    }

    @media (max-width: 1024.98px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: 768.98px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 576.98px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 425.98px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.segment-list {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @media (max-width: 1376.98px) {
        padding: 0 1rem;
    }

    // > div {

    //     margin-top: 64px;

    //     &:first-child {
    //         margin-top: 0;
    //     }

    //     &:last-child {
    //         margin-bottom: 4rem;
    //     }
    // }
}

// .segmentHeader {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     gap: 2rem;
//     margin-bottom: 1rem;

//     .titleSegment {
//         display: flex;
//         flex-direction: column;

//         h1 {
//             font-size: 1.75rem;
//             color: $header-color;
//             margin-bottom: 0;
//             width: fit-content;
//             line-height: 1.6;
//             @include setWordwrap(1);

//             @media (max-width: 768.98px) {
//                 font-size: 1.5rem;
//             }

//             @media (max-width: 414.98px) {
//                 font-size: 1.25rem;
//             }
//         }

//         h3 {
//             font-size: 1rem;
//             color: var(--title);
//             margin-bottom: 0;
//             line-height: 1.6;
//             font-weight: 400;
//         }
//     }

//     a {
//         color: $header-color;

//         &:hover {
//             opacity: 0.7;
//         }
//     }
// }

.store {
    h1 {
        @media (max-width: 576.98px) {
            font-size: 1.5rem;
            padding: 0;
        }
    }
}

.bannerSwiper {
    margin: 0 auto 0;
    margin-bottom: 4rem;
    image-rendering: -webkit-optimize-contrast;

    .bannerHome {
        .slide {
            border-radius: 10px;
            $banner-ratio: 2.5;
            width: calc((100vw - (4px * 5)) / 4.5);
            height: calc((100vw - (4px * 5)) / 4.5 / var($banner-ratio));
            border-radius: unset;

            @media (max-width: 2048px) {
                width: calc((100vw - (4px * 4)) / 3.5);
                height: calc((100vw - (4px * 4)) / 3.5 / var($banner-ratio));
            }
            @media (max-width: 1024.98px) {
                width: calc((100vw - (4px * 3)) / 2.5);
                height: calc((100vw - (4px * 3)) / 2.5 / var($banner-ratio));
            }
            @media (max-width: 768.98px) {
                width: calc((100vw - (4px * 2)) / 1.2);
                height: calc((100vw - (4px * 2)) / 1.2 / var($banner-ratio));
            }
            @media (max-width: 576.98px) {
                width: 100%;
            }

            > img {
                padding-left: 4px;
                padding-right: 4px;
                height: auto;
                width: 100%;
                image-rendering: -webkit-optimize-contrast;
                &.link {
                    cursor: pointer;
                }
            }
        }
    }
    .img {
        @include setNoHighlight();
    }
}

.category {
    text-align: center;
    padding: 1rem;
    background-color: $surface-color;
    cursor: pointer;

    .title {
        @include setWordwrap(1);
        min-height: 20px;
        color: $onprimary-color;
        max-height: 1.4em;
        margin-bottom: 0.5rem;
    }

    img {
        margin: $minspace auto #{$space};
        border-radius: 0.25rem;
    }
}

.ebookDetail {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 1rem;

    .header-title {
        color: $header-color;
    }

    .tagContainer {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;

        @media (max-width: 768.98px) {
            margin-bottom: 1rem;
        }
    }

    .title {
        @include setHeader;
        color: $header-color;
        text-align: center;
        font-size: 26px;
        font-weight: 600 !important;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .column {
        display: flex;
        gap: 1rem;

        .left-detail {
            width: 40%;

            @media (max-width: 576.98px) {
                width: 100%;
            }

            .cover {
                width: 100%;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .right-detail {
            width: 60%;

            @media (max-width: 576.98px) {
                width: 100%;
            }

            .detail-ebook {
                font-family: $font-head;
                font-size: 16px;
                color: $grey2-color;
            }

            .columnNumber {
                .ant-col {
                    text-align: center;
                    width: calc(100% / 4);
                }

                &.full {
                    .ant-col {
                        width: calc(100% / 2);
                    }
                }
            }

            .columnFile {
                .ant-col {
                    text-align: center;
                    width: calc(100% / 3);
                }
            }

            .detailNumber {
                > div:first-child {
                    @include setHeaderBold(400);
                    font-size: $font-head-size;
                    color: $title-color;
                    @media (min-width: 541px) and (max-width: 680px) {
                        font-size: 12px;
                    }
                }
                > div:last-child {
                    @include setHeaderBold(500);
                    font-size: 30px;
                    line-height: 40px;
                    color: $hti-secondary-color;
                    @media (min-width: 541px) and (max-width: 680px) {
                        font-size: 24px;
                    }
                }
                &.disable {
                    > div:last-child {
                        color: $disable-color !important;
                    }
                }
            }
        }

        @media (max-width: 600px) {
            flex-flow: column wrap;

            .leftDetail,
            .rightDetail {
                width: 100%;
            }
        }
    }

    .description {
        font-family: $font-body;
        color: $grey2-color;
    }

    .ant-divider {
        margin: 16px 0;
        opacity: 0.2;
        border-top-color: $title-color;
    }

    .ant-tag {
        height: 30px;
        border-radius: 15px;
        border: unset;
        padding: 6px 16px;
        background-color: #e5e5e5;

        > * {
            @include setHeader;
        }
    }

    .columnButton {
        display: flex;
        margin-top: 1rem;

        > .boxSampleButton {
            width: 34%;
            padding-right: $minspace;

            > button {
                width: 100%;
            }
        }

        > .boxRentButton {
            width: 66%;

            > button {
                width: 100%;
            }
        }

        &.noSample {
            > .boxSampleButton {
                width: 0;
                display: none;
            }

            > .boxRentButton {
                width: 100%;
            }
        }
    }
}

.ebookDetail.video {
    .ant-divider {
        padding-bottom: 0px;
    }

    .flex {
        align-items: center;
        justify-content: center;
    }

    .title {
        text-align: left;
        padding: 0;
        margin-top: $space;
        margin-bottom: $space;
    }

    .videoData {
        padding-bottom: 0;
    }

    .view {
        font-family: $font-body;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-bottom: 0px;

        .view-count {
            color: #828282;
            font-size: 14px;
            text-align: left;
        }

        .ant-divider {
            width: $space;
            height: 16px;
            margin: 4px 8px;
            padding: 0;
            width: 1px;
        }

        .boxElement {
            align-items: center;
            justify-content: space-around;

            > div {
                display: flex;
                align-items: center;
                .hide {
                    display: block;
                    margin: 0 4px;
                }
            }

            .ant-divider {
                display: block;
            }

            @media (max-width: $max-width-xs) {
                > div {
                    display: block;
                    .hide {
                        display: none;
                    }
                }
                .ant-divider {
                    display: none;
                }
            }

            .text-primary-big {
                @include setHeader;
                color: $title-color;
                font-size: 14px;
                justify-content: center;
            }

            .text-second-big {
                @include setHeaderBold(500);
                color: $secondary-color;
                font-size: 20px;
                text-align: center;
            }
        }

        @media (max-width: $max-width-xs) {
            display: block;
            .view-count {
                margin-top: -16px;
                margin-bottom: 16px;
            }
        }
    }

    .boxChannel {
        display: flex;
        justify-content: space-between;

        .channelImage {
            width: 40px;
            border-radius: 50%;
            margin-right: 8px;
        }
        @media (max-width: $max-width-xs) {
            display: block;
            .ant-btn {
                width: 100%;
                margin-top: 16px;
            }
        }
    }
}

.ebookDetail.podcast {
    .column {
        display: flex;
        gap: 2rem;
        margin-top: 1rem;

        .left-detail {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 40%;

            @media (max-width: 768.98px) {
                width: 100%;
            }

            .title {
                font-size: 1.25rem !important;
                margin-bottom: 0;
                color: var(--grey2);
            }
        }

        .right-detail {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            @media (max-width: 768.98px) {
                width: 100%;
            }

            .boxHeaderEP {
                font-family: $font-head;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 16px;

                .segmentHeader {
                    margin: 0;
                    text-align: left;
                }

                > div > div:last-child {
                    margin-left: 16px;
                }

                .ant-dropdown-trigger {
                    cursor: pointer;
                }
            }

            .boxEpisode {
                border-bottom: 1px solid $grey6-color;
                padding-top: 16px;
                padding-bottom: 16px;

                p {
                    margin-bottom: 0;
                }

                .flex-container {
                    display: flex;
                    align-content: center;
                    justify-content: space-between;

                    &:last-child {
                        align-items: center;
                    }
                }

                .episode-title {
                    color: var(--grey2);
                    font-family: Prompt, tahoma, sans-serif !important;
                    font-size: 1rem;
                    font-weight: 500;
                    margin: 0;
                }

                .time {
                    margin-bottom: 0;
                    color: var(--grey4);
                    line-height: 1.6;
                }

                .duration {
                    @include setHeaderBold(300);
                }

                .episodeButton {
                    border: none;
                    border-radius: 15px;
                    padding: 0;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .nodata {
                height: 250px;
                p {
                    font-size: 16px;
                }
            }
        }

        @media (max-width: 768px) {
            display: block;
            .leftDetail,
            .rightDetail {
                width: 100% !important;
            }
        }
    }

    .subtitle {
        @include setHeaderBold(500);
        color: $grey2-color;
        font-size: 16px;
        text-align: center;
    }
}

.cover,
.shelf-item {
    .filetype {
        margin-left: $minspace !important;
    }
}

.cover {
    @include setShadow;
    border-radius: 0.25rem;

    &:hover {
        cursor: pointer;
    }
}

.cover,
.shelf-item {
    position: relative;
    background-color: transparent;
    overflow: hidden;

    > .ant-image,
    img {
        position: unset;
        text-align: center;
        display: flex;
        margin: 0 auto;
        // border-radius: 0.25rem;
    }

    img.col-ebook-image {
        margin: inherit !important;
    }

    .filetype {
        position: absolute;
        padding: 0 0.5rem;
        color: #fff;
        font-size: 0.625rem;
        border-radius: 0 0 0.25rem 0.25rem;
        text-transform: uppercase;
        line-height: 1.6;

        &.pdf {
            background-color: #d30000;
        }

        &.epub {
            background-color: #639f00;
        }
    }

    .total-time {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 10px;
        padding: 4px;
        color: white;
        margin: 0 8px 8px 0;
        background-color: rgba(0, 0, 0, 0.7);
    }
}

.coverVideoContainer,
.videoPlayerView {
    margin-top: 16px;
    border-radius: 0.5rem;
    overflow: hidden;
    width: 100%;
    height: calc(100vw / #{$cover-video-ratio});
    background-color: #ffffff;

    @media (min-width: 1056px) {
        height: calc(1024px / #{$cover-video-ratio});
    }

    @media (max-width: 375px) {
        height: calc(375px / #{$cover-video-ratio});
    }

    > img {
        width: 100%;
    }
}
.shelf-item {
    overflow: unset !important;
}

.badges {
    font-family: Prompt;
    margin: 0px 0px 20px;
    .badge-list {
        text-align: center;
        width: 100%;
        display: flex;
        &::-webkit-scrollbar {
            display: none;
        }
        padding-top: 4px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        .link {
            display: inline-table;
            width: 160px;
            height: 160px;
            text-align: center;
            .gradient {
                outline: none;
                border: none !important;
                border-radius: 4px;
                width: 100%;
                text-align: center;
            }
        }
    }
    .badge-text {
        font-weight: 600;
        font-size: 23px;
        color: #012875;
        margin-bottom: 2px;
    }
}

.ant-btn:not(.ant-input-search-button):not(.accept-button),
.login-button {
    @include setHeader;
    > * {
        @include setHeader;
    }

    font-weight: 500 !important;
    cursor: pointer;
    &.Sample {
        background-color: transparent;
        border: 1px solid $title-color;
        color: $title-color;
        &:hover {
            background-color: $title-color;
            border: 1px solid $title-color;
            color: $title-color;
        }
    }
    &.Rented {
        border: none;
        background-color: $disable-button-color;
        border-color: $disable-button-color;
        color: $disable-color;
    }
    &.Disabled {
        border: none;
        background-color: $disable-button-color;
        border-color: $disable-button-color;
        color: $disable-color;
    }
    &.Rent {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $onprimary-color;
    }
    &.WaitingList {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $onsecondary-color;
    }
    &:hover {
        opacity: 0.9;
    }
    &[disabled]:hover,
    &[disabled]:focus {
        background-color: $disable-button-color;
        border-color: $disable-button-color;
        color: $disable-color;
    }
}

.boxSampleButton {
    button {
        border: 1px solid var(--header);
        background-color: transparent;
        color: var(--header);

        &:hover {
            background-color: var(--grey6);
            border-color: var(--header);
            color: var(--header);
        }
    }
}

.boxRentButton {
    &[disabled]:hover,
    &[disabled]:focus {
        background-color: $disable-button-color;
        border-color: $disable-button-color;
        color: $disable-color;
    }
}

.ant-modal-content {
    .ant-modal-footer {
        button {
            min-width: 100px;
        }
    }
}

.cover {
    .ant-image-img {
        width: 100%;
        height: auto;
        border-radius: 0.75rem;
    }
}

a {
    @include setHeader;
}

a,
a:hover,
a:hover {
    color: $header-color;
}

.bestSeller .rank .titleNumber,
.login-form .h3-head {
    color: $header-color !important;
}

.bestSeller .rank .queueNumber {
    color: $hti-secondary-color !important;
}

.relate-view-container {
    .swiper-wrapper {
        align-items: baseline;
    }
}
