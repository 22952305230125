.category {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    opacity: 0;
    border-radius: 0.5rem;

    .cover {
        display: flex;
        align-items: center;
        max-width: -webkit-fill-available;

        .cover {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - (0.5rem * 5));
            transition: transform 0.5s;
            max-width: -webkit-fill-available;
            border-radius: 0.5rem;

            &:hover {
                transform: scale(1.05);
                transition: transform 0.3s;
            }

            img.ebook {
                width: auto;
                height: 100%;
                max-height: 170px;
            }

            img.video {
                width: 100%;
                height: auto;
                max-height: 170px;
            }
        }

        img.ebook {
            width: auto;
            height: 100%;
            max-height: 170px;
        }

        img.video {
            width: 100%;
            height: auto;
            max-height: 170px;
        }
    }

    .bigCat {
        max-width: 180px;
        margin: 0 auto !important;
        filter: unset !important;
    }

    .bigCat:hover {
        transform: unset !important;
    }
}

.fadeIn {
    animation: fadeIn 0.5s ease-in-out 0s 1 both;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
