div.podcast-detail {
    width: 100%;
    min-width: 64rem;

    .view-count {
        color: #828282;
        font-size: 0.875rem;
    }

    .channel-name {
        color: #4f4f4f;
        font-size: 1rem;
        margin-bottom: 0;
    }
    .follow-btn {
        color: #fd6b53;
        font-size: 0.75rem;
    }
    div.podcast-property {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        h3 {
            font-size: 0.75rem;
            color: #012875;
            display: flex;
            align-items: center;
            span {
                margin-left: 0.5rem;
                color: #fd6b53;
                font-size: 1.25rem;
            }
        }
    }

    div.podcast-action {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    div.container {
        // min-height: 85vh;
        margin: auto;
        max-width: 64rem;
    }

    h2.section-title {
        color: #012875;
        font-size: 1.25rem;
        font-weight: 600;
    }

    h2.podcast-title {
        margin-top: 1rem;
        color: #1c1c1e;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.2;
        text-align: center;
    }

    h2.podcast-subtitle {
        margin-top: -0.25rem;
        color: #333333;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
    }

    div.cover {
        height: auto;
        margin: auto;
        img {
            margin: auto;
            width: 100%;
        }
    }

    .ant-divider-horizontal {
        margin: 0.75rem 0;
    }

    .grey-text {
        font-family: Prompt;
        color: #4f4f4f;
        font-size: 0.875rem;
    }

    .hours-label {
        text-align: end;
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-family: Prompt;
    }

    .ant-tag {
        height: 1.875rem;
        border-radius: 0.9375rem;
        border: unset;
        padding: 0.375rem 1rem;
        background-color: #e5e5e5;
        margin-bottom: 0.5rem;
        font-family: Prompt;
        color: #4f4f4f;
        font-size: 0.875rem;
    }

    .ep-title {
        font-family: Prompt;
        font-weight: 00;
        font-size: 0.875rem;
        color: black;
        margin-bottom: 0;
    }

    .ep-date {
        font-family: Prompt;
        font-weight: 400;
        font-size: 0.75rem;
        color: #828282;
        text-align: end;
        margin-bottom: 0;
    }

    // .ep-description {
    //      font-weight: 400;
    //      font-size: .75rem;
    //      color: #4f4f4f;
    //      margin-top: .25rem;
    //      .text {
    //           font-size: .75rem;
    //           font-family: Prompt;
    //      }
    //
    // }
    .read-or-hide {
        display: flex;
        justify-content: flex-end;
        margin-top: -1.875rem;
        cursor: pointer;
    }
    .ep-description {
        font-weight: 400;
        font-size: 0.75rem;
        color: #4f4f4f;
        margin-top: 0.25rem;
    }

    details {
        position: relative;
    }
    details summary {
        display: block;
        cursor: pointer;
        color: #1c1c1e;

        font-size: 0.75rem;
    }
    details summary:focus {
        outline: none;
    }
    details[open] {
        display: block;
        padding-bottom: 1.5625rem;
        padding-top: 0.625rem;
        // animation: open 0.2s linear;
    }
    .-less {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 33.125rem;
        margin-bottom: 0.625rem;
    }
    details[open] summary {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    details #open {
        padding-left: 0.3125rem;
        text-align: middle;
    }
    details #open:after {
        display: inline-block;
        position: relative;
        padding-left: 0.5rem;
    }
    details #open:before {
        content: '...';
    }
    details[open] #open {
        display: none;
    }
    details #close {
        display: none;
    }
    details #close:before {
        content: '<';
        margin-right: 0.1875rem;
    }
    details[open] #close {
        display: block;
        font-family: 'Prompt';
    }
    details[open] #open {
        display: none;
    }

    ::-webkit-details-marker {
        display: none;
    }

    @keyframes open {
        0% {
            opacity: 0;
            // transform: translateY(-0.625rem);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
.sort-by > span {
    font-family: 'Prompt';
}
.sort-by {
    font-family: 'Prompt';
}
.-moredetail > p {
    display: inline !important;
    font-family: Prompt;
}
.-moredetail {
    h1,
    h2,
    h3,
    h4,
    strong,
    span {
        font-family: Prompt !important;
    }
}
.-detail > p {
    font-family: Prompt;
}
div.podcast-detail details[open] summary > p {
    display: none;
}

div.collection-list {
    display: flex;
    align-content: space-between;
    margin-bottom: 3.125rem;
}

div.podcast-collection {
    width: 100%;
    margin-right: 0.5rem;

    div.podcast-content {
        // height: 10.375rem;
        width: 100%;
        background-color: blanchedalmond;
        position: relative;

        img.coverImage {
            width: 100%;
            image-rendering: -webkit-optimize-contrast;
        }

        div.total-time {
            padding: 0.125rem;
            font-size: 0.625rem;
            background-color: black;
            color: white;
            position: absolute;
            bottom: 0.25rem;
            right: 0.25rem;
        }
    }

    h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.875rem;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0.25rem;
        color: #030303;
    }
    h4 {
        font-size: 0.75rem;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0.25rem;
        color: #606060;
    }
    p {
        font-size: 0.75rem;
        color: #606060;
    }
}
.ep-item {
    padding: 0.25rem;
    width: 34.25rem;
    overflow: hidden;
    .list-button-left {
        justify-content: flex;
        display: flex;
    }
    .list-button-right {
        justify-content: flex-end;
        display: flex;
    }
    .list-button {
        justify-content: flex-end;
        display: flex;

        .episode-player-play {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding: 0 1.25rem;
            .player-slider {
                width: 50%;
            }
            img {
                width: 1.25rem;
                padding-left: 0.625rem;
            }
        }
        .episode-player-stop {
            display: flex;
            justify-content: flex-end;
            width: 80%;
            .player-slider {
                width: 50%;
            }
            .img {
                width: 20%;
                padding-left: 0.625rem;
            }
            p {
                color: #012875;
                height: 100%;
                align-items: center;
                font-size: 1rem;
                padding: 0 0.625rem 0 0.625rem;
                font-family: Prompt;
                font-weight: 500;
                margin-top: unset !important;
                margin-bottom: unset !important;
            }
        }
    }

    .ep-slider {
        width: 100%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .ep-button {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: flex-end;
    }
    button {
        border: rgb(1, 40, 117);
        box-shadow: none !important;
        // background: none;
        // color: #999;
        font-family: Prompt;
        cursor: pointer;
    }
}
.rm-more-button {
    border: #012875;
    box-shadow: none !important;
    font-family: Prompt;
    cursor: pointer;
    padding: 0.1875rem;
    border-radius: 0.1875rem;
}

.list-icon-left {
    width: 1rem;
    display: flex;
    align-items: center;
    padding: 0.625rem;

    .ant-btn {
        width: 1rem;
    }

    > button {
        background-color: unset;
        cursor: pointer;
    }
}

.podcast-player {
    width: 100%;
    padding: 1rem;
    box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.2);
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--modal-background);

    video,
    audio {
        width: 100%;
    }

    h2 {
        font-size: 1.25rem;
        width: 100%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        color: var(--title);
    }

    .media-button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .list-button-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 1rem;
        }

        .player-button {
            width: fit-content;
            height: fit-content;
            padding: 0;
            color: var(--hti-primary-light);
            background: unset;
            border: unset;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 1.25rem;
                height: 1.25rem;
            }

            span {
                font-size: 1.25rem;
                font-weight: 500;
            }
        }
    }

    .media-slider-container {
        display: flex;
        flex-direction: column;

        .media-time {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > p {
                font-size: 0.875rem;
                font-weight: 600;
                color: #ff5337;
                margin-bottom: 0;
            }
        }

        .player-slider {
            width: 100%;
        }
    }
}

.ep-container {
    flex: 1 1 auto;
    height: 0rem;
    overflow: hidden;
}

.ep-container:hover {
    overflow-y: auto;
}

.ep-container-non {
    flex: 1 1 auto;
    overflow-y: auto;
    height: auto;
}

::-webkit-scrollbar {
    height: 0.625rem;
    width: 0.625rem;
    background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 6.25rem;
    border: solid transparent;
    background-color: #c5c5c5;
    background-clip: content-box;
}

.read-more-less--more,
.read-more-less--less {
    color: #999;
}

.related-podcast {
    width: 100%;
    margin-bottom: 4rem;

    h2 {
        color: #012875;
        font-size: 1.25rem;
    }
    .podcast-items {
        display: flex;
        justify-content: flex-start;

        .related-item {
            margin-right: 1rem;

            .cover-image {
                height: 11.375rem;
                width: 11.375rem;
                background: #828282;
                -webkit-filter: drop-shadow(0rem 0rem 0.25rem rgba(0, 0, 0, 0.1));
                filter: drop-shadow(0rem 0rem 0.25rem rgba(0, 0, 0, 0.1));
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            &-title {
                white-space: nowrap;
                width: 9.375rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .box {
                margin-top: 4rem;
            }

            h3 {
                font-size: 0.75rem;
                font-weight: 600;
                color: #012875;
                margin: 0;
                margin-top: 0.5rem;
            }

            h4 {
                color: #4f4f4f;
                font-size: 0.6875rem;
                font-weight: normal;
            }
        }
    }
}
.sound-animation {
    width: 2rem;
    height: 2rem;
    overflow: hidden;

    svg {
        transform: scale(1.5) translateX(-0.25rem) !important;
    }
}
.podcast-right-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.podcast-left-content {
    .btn-buy {
        display: flex;
        justify-content: center;
        button {
            display: inline-block;
            width: 100%;
            font-weight: 300;
            font-size: 1em;
            height: 3rem;
            line-height: 0.1em;
            padding: 0.2em !important;
            color: #fff;
            border: none;
            background: #012875;
            border-radius: 6.25rem;
            cursor: pointer;
            font-family: Prompt;
        }

        button:focus {
            outline: none;
        }
        button:hover {
            background-color: #012875c9;
        }
    }
}

.divider {
    &-podcast {
        height: 100%;
        background-color: #e0e0e0;
    }
    &-podcastep {
        width: 100%;
        background-color: #e0e0e0;
        margin: '0';
    }
    &-content {
        width: 100%;
        background-color: #e0e0e0;
    }
}
