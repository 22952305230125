@import '/public/style/base';

.contentDetailModal {
    .ant-modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        min-height: 50vh;
        position: relative;

        img.coverImage {
            max-width: 250px;
            min-width: 150px;
            width: calc(100vw - 800px);
        }
    }

    .uid {
        border: none;
        display: flex;
        align-items: center;

        input {
            margin-left: 5px;
            background-color: #f2f2f2;
            border: none;
            border-radius: 6px;
            width: 326px;
            padding: 6px;
        }
        .copyButton {
            margin-left: 5px;
            border: none;
            border-radius: 6px;
            background-color: #f2f2f2;
            cursor: pointer;
            img {
                width: 32px;
                height: 32px;
            }
        }
    }

    .modal-ebook {
        width: 100%;

        .modal-ebook-top {
            display: flex;
            gap: 2rem;
            margin-bottom: 2rem;

            .uid {
                margin: 1em 0;
            }
        }

        .modal-ebook-synopsis {
            margin-bottom: 30px;
            .title {
                font-weight: 1000;
                font-size: 1.5rem;
            }
        }

        .file-detail {
            display: flex;
            justify-content: space-around;

            .file-detail-col {
                text-align: center;
                justify-content: center;

                .detailNumber {
                    font-size: 30px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                .title {
                    font-size: 20px;
                }
            }
        }

        .title {
            color: $header-color !important;
        }

        .head {
            color: $grey1-color !important;
        }

        .detail {
            color: $grey4-color !important;
        }

        .detailNumber {
            color: $hti-secondary-color;
        }
    }
}
