@import '/public/style/base';

.footer {
    background-color: var(--primary-dark);
    color: var(--on-primary);

    .flex-container {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
        padding-top: 1rem;
        gap: 2rem;

        @media (max-width: 576.98px) {
            flex-direction: column;
            gap: 1rem;
        }
    }

    .col {
        width: calc(100% / 3);

        &:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }

        &:nth-child(3) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        @media (max-width: 576.98px) {
            width: 100%;

            &:nth-child(1),
            &:nth-child(3) {
                text-align: center;
                align-items: center;
            }
        }
    }

    h1 {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 0.5rem;
        color: var(--on-primary);
        padding: 0;
    }

    h4 {
        margin-bottom: 0.5rem;
        font-size: 1.125rem;
        font-weight: 500;
        color: var(--on-primary);
    }

    p {
        font-size: 0.75rem;
        margin-bottom: 0;
        line-height: 1.6;
    }

    .social-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;

        @media (max-width: 576.98px) {
            justify-content: center;
        }

        svg {
            width: 30px;
            height: 30px;
            cursor: pointer;
            color: var(--on-primary);

            &:hover {
                transform: scale(1.05);
                transition: 0.3s;
            }
        }
    }

    .app-icon {
        width: 100%;
        max-width: 150px;
        height: auto;
    }

    .footer-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        margin-top: 1rem;

        @media (max-width: 576.98px) {
            flex-direction: column;
            justify-content: center;
        }

        > div {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
            color: var(--on-primary);
        }
    }
}
