.viewAllSponsor {
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
    padding: 4rem 1rem;
    min-height: calc(100vh - 105px);
    position: relative;

    .pageTitle {
        color: var(--title);
        font-size: 2rem;
        margin-bottom: 3rem;
        text-align: center;
    }

    .component-loading {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .segmentContainer {
        display: flex;
        flex-direction: column;
        height: fit-content;
        gap: 3rem;

        .titleHeader {
            font-size: 1.25rem;
            margin-bottom: 1rem;
            text-align: left;
        }
    }

    .gridContainer {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
        gap: 1rem;

        .contentBox {
            display: flex;
            align-items: center;
            gap: 1rem;
            background-color: var(--modal-background);
            border-radius: 0.5rem;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
            padding: 1rem;
            transition: all 0.3s;

            &:hover {
                cursor: pointer;
                transform: scale(1.05);
                background-color: var(--grey6);
            }

            .coverImage {
                width: 60px;
                height: 60px;
                display: flex;
                border-radius: 50%;
                overflow: hidden;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }

            p {
                margin-bottom: 0;
                color: var(--grey1);
                font-size: 0.875rem;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
}
