.voteEbookDetailModal {
   .ant-modal-body {
      padding: 2rem;
      // background: var(--surface);
      border-radius: 0.5rem;
   }

   h1 {
      font-size: 1.625rem;
      margin-bottom: 1rem;
      color: var(--grey1);
   }

   .contentContainer {
      display: flex;
      gap: 1rem;

      .coverImage {
         width: 35%;
      }

      .detailContainer {
         width: 65%;
         display: flex;
         flex-direction: column;
         gap: 0.25rem;

         p {
            margin-bottom: 0;
            color: var(--grey1);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 10;
            overflow: hidden;
         }

         .paragraphTitle {
            margin-bottom: 0.5rem;
            text-align: left;
         }

         .tagContainer {
            gap: 0.5rem;
            padding: 1rem 0;
            margin: 0.25rem 0;
            border-top: 1px solid var(--grey6);
            border-bottom: 1px solid var(--grey6);
         }
      }
   }
}

.AlertVoteContentModal {
   .ant-modal-body {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      text-align: center;
      border-radius: 0.5rem;
   }

   h1 {
      font-size: 1.25rem;
      margin-bottom: 0;
   }

   p {
      color: var(--grey1) !important;
      margin-bottom: 0;

      span {
         color: var(--secondary);
         font-weight: 500;
      }
   }

   button.accept {
      border: none;
      background: var(--hti-primary-light);
      color: var(--surface);
      height: 2.5rem;
      width: 120px;
   }
}
