.rental-modal {
    .anticon {
        color: var(--on-background);
    }
    .ant-modal-body {
        p {
            margin-bottom: 0.5em;
            text-align: center;
        }
        h3 {
            text-align: center;
        }
    }

    .ant-modal-title {
        font-family: 'Prompt';
    }

    .modal-detail {
        .modal-lottie {
            width: 150px;
            height: 150px;
            margin: auto;
        }
        h3 {
            margin: auto;
            text-align: center;
        }
    }
    .ant-modal-footer {
        text-align: center;
        border-top: 0;
    }
    .ant-modal-header {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }

    .ant-btn-ghost {
        border-color: var(--header);
        background-color: transparent;
        color: var(--header);

        &:hover {
            background-color: var(--grey6);
            border-color: var(--header);
            color: var(--header);
        }
    }

    .ant-btn-primary {
        color: var(--surface);
        background: var(--header);
        border-color: var(--header);

        &:hover {
            color: var(--on-primary-dark);
            background: var(--primary-variant);
            border-color: var(--primary-variant);
        }
    }

    @keyframes modalFadeIn {
        0% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
}

.reading-stat-modal {
    width: 100% !important;
    max-width: 950px !important;

    .ant-modal-body {
        background: #fff !important;
        position: relative;
        border-radius: 5px;
        min-height: 50vh;
    }

    p,
    label {
        font-family: 'Prompt' !important;
    }

    .ant-modal-body {
        padding: 1rem 2rem !important;

        @media (max-width: 1024.98px) {
            padding: 1rem !important;
        }
    }

    .title {
        color: #012876 !important;
        padding-top: 0 !important;
        margin-bottom: 1rem;
        line-height: 2rem;
    }

    .reading-stat-footer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .reading-stat-action {
            display: flex;
            gap: 10px;
            svg {
                cursor: pointer;
            }
        }
    }

    .modal-container {
        display: flex;
        gap: 1.5rem;
        position: relative;
        min-height: 680px;

        @media (max-width: 768.98px) {
            flex-direction: column;
        }

        .left-column {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: 768.98px) {
                width: 100%;
            }

            .text-center {
                text-align: center;
            }

            .book-group {
                text-align: center;

                img {
                    height: 500px;
                    width: auto;
                    margin-bottom: 16px;
                    max-width: 100%;
                }

                .description {
                    margin-bottom: 32px;
                    text-align: center;

                    .title-content {
                        font-size: 16px;
                        font-weight: 400;
                        color: #180f20 !important;
                        font-family: 'Prompt' !important;
                    }

                    .author {
                        color: var(--grey3) !important;
                        font-family: 'Prompt' !important;
                    }

                    .publisher {
                        color: var(--grey4) !important;
                        font-family: 'Prompt' !important;
                    }
                }
            }

            .license-detail-group {
                width: 100%;
                background-color: #f2f2f2;
                border-radius: 1.25rem;
                padding: 1rem;
                display: flex;
                justify-content: space-between;

                @media (max-width: 768.98px) {
                    justify-content: space-around;
                }

                .label-value {
                    font-size: 16px;
                    font-weight: 500;
                    color: #012876;
                }

                label {
                    font-size: 12px;
                    font-weight: 400;
                    color: #012876;
                }
            }
        }

        .right-column {
            width: 50%;
            height: 100%;
            max-height: 696px;
            overflow: auto;
            padding-right: 0.5rem;

            @media (max-width: 768.98px) {
                width: 100%;
            }

            &::-webkit-scrollbar {
                width: 5px;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track-piece {
                width: 10px;
                background-color: #e2e2e2be !important;
                border-radius: 16px;
            }

            &::-webkit-scrollbar-thumb {
                background: #bdbdbd;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #a0a0a0;
            }

            .flex {
                display: flex;
            }

            .flex-col {
                flex-direction: column;
            }

            .align-center {
                align-items: center;
            }

            .align-start {
                align-items: flex-start;
            }

            .justify-center {
                justify-content: center;
            }

            .justify-space-between {
                justify-content: space-between;
            }

            .gap-8 {
                gap: 0.5rem;
            }

            .float-text {
                font-size: 0.625rem;
                color: var(--grey4) !important;
                width: 100%;
                text-align: center;
                margin-bottom: 4px !important;
            }

            .progress-percent-reading {
                margin-top: 1rem;
                width: 100%;

                .ant-progress-circle-path {
                    stroke: #fd6b53;
                }

                .ant-progress-text {
                    color: #012876 !important;
                    font-weight: 700;
                }

                .ant-progress-circle .ant-progress-inner {
                    background-color: #f2f2f2;
                }
            }

            .progress-day-reading {
                width: 100%;
                text-align: center;

                .ant-progress-circle-path {
                    stroke: #fd6b53;
                }

                .active-reading {
                    color: #fd6b53 !important;
                    font-weight: 600;
                    text-align: center;
                }

                .ant-progress-text {
                    color: #012876;
                    font-weight: 700;
                }

                .ant-progress-circle .ant-progress-inner {
                    background-color: #fff;
                }

                .ant-progress-text {
                    font-size: 10px;
                }

                .time-reading {
                    font-size: 0.75rem;
                }
            }

            .day-time {
                p.time {
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 1.5rem;
                    color: #012876;
                    margin-bottom: 0px;
                }

                p.day {
                    font-size: 1.125rem;
                    line-height: 1.5rem;
                    color: #79797f;
                }
            }

            .custom-progress {
                width: 10px;
                height: 70px;
                border-radius: 5px;
                overflow: hidden;
                background-color: #fff;
                align-items: flex-end;

                > div {
                    background-color: #fd6b53;
                    width: 100%;
                    border-radius: 5px;
                }
            }

            .read-time-graph-container {
                gap: 1rem;

                > div {
                    width: 100%;
                    background-color: #f2f2f2;
                    height: auto;
                    border-radius: 1.25rem;
                    padding: 1rem;

                    p {
                        color: #012876;
                        margin-bottom: 0;
                    }
                }
            }

            .reading-list-box {
                width: 100%;
                padding: 0.75rem;
                background-color: #f2f2f2;
                border-radius: 0.9375rem;
                margin-bottom: 1rem;

                .ant-progress-circle .ant-progress-inner {
                    background-color: #f2f2f2;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .reading-date {
                    margin: 0.25rem 0;
                    font-size: 0.75rem;
                    color: #4f4f4f;
                }

                .reading-time {
                    font-size: 0.875rem;
                    font-weight: 400;
                    color: #012876;
                    margin-bottom: 0;
                    line-height: 0.875rem;
                }

                .date-text {
                    font-size: 0.625rem;
                    color: var(--grey3);
                    margin-bottom: 0;
                }
            }

            h4.title {
                font-size: 1rem;
                margin-bottom: 0;
                color: #012876;
            }
        }
    }
}

.download-stat {
    height: 900px;
    width: 900px;
    background-color: white;
    background-size: 900px;
    z-index: -999;
    position: absolute;
    top: 0;
    left: 0;

    .text-center {
        text-align: center;
    }

    h1.download-stat-title {
        color: #012876 !important;
        padding-top: 0 !important;
        margin-bottom: 0;
        line-height: 2rem;
    }

    h3.download-stat-title,
    h4.download-stat-title {
        color: #012876 !important;
        padding-top: 0 !important;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
        line-height: 1rem;
    }

    .download-stat-content-container {
        position: relative;
        padding: 120px 70px;
        width: 100%;
        height: 100%;

        .download-stat-content {
            width: 100%;
            height: 100%;
            text-align: center;
        }

        .stat-content {
            display: flex;
            gap: 2rem;

            .left-column {
                width: 100%;

                img {
                    height: 450px;
                    width: auto;
                    margin-bottom: 16px;
                    max-width: 100%;
                }

                .description {
                    margin-bottom: 32px;
                    text-align: center;

                    .title-content {
                        font-size: 16px;
                        font-weight: 400;
                        color: #180f20 !important;
                    }

                    .author {
                        color: var(--grey3) !important;
                    }

                    .publisher {
                        color: var(--grey4) !important;
                    }
                }
            }

            .right-column {
                width: 100%;
                flex-direction: column !important;

                .goal-label {
                    font-size: 1rem;
                    // line-height: 1rem;
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                }

                .download-app-label {
                    font-size: 0.875rem;
                    color: #012876 !important;
                    padding-top: 0 !important;
                }

                .download-app {
                    display: flex;
                    justify-content: center;
                    gap: 0.5rem;
                    width: 80%;
                    margin: auto;

                    > div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .app-store {
                        width: auto;
                        height: 2rem;
                        margin-bottom: 0.5rem;
                    }

                    .play-store {
                        width: auto;
                        height: 2rem;
                        margin-bottom: 0.5rem;
                    }
                }

                .qrcode-download {
                    width: 100px;
                    height: 100px;
                }

                .link-download-section {
                    p {
                        color: var(--grey4);
                        margin-bottom: 0;
                    }

                    p.scan-text {
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                        font-weight: 400;
                    }

                    p.link-text {
                        font-size: 0.75rem;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

.stat-download-curtain {
    position: relative;
    background-color: white;
}

.limit-two-line {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
