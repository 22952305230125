.goal-modal {
  min-height: 200px;

  h1 {
    color: var(--primary) !important;
  }

  div.container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }

  .flex-col {
    flex-direction: columns;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.3);
  }

  button {
    width: 100%;
    max-width: 150px;
    height: 48px !important;
    padding: 0 1rem !important;
    background: var(--primary) !important;
    color: #fff !important;
    border-color: var(--primary) !important;

    &:hover {
      border-color: var(--primary) !important;
    }
  }

  .content-container {
    display: flex;
    justify-content: center;
  }
}
