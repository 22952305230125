.sign-in-sso-modal {
    .ant-modal-body {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
        height: fit-content;
    }

    img {
        width: 150px;
        height: auto;
    }

    h1 {
        color: var(--hti-primary-light) !important;
        margin-bottom: 1rem;
    }

    .clientCard {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 1px solid #bdbdbd;
        padding: 0.5rem;
        border-radius: 3rem;

        p {
            margin-bottom: 0;

            &:first-child {
                font-size: 1rem;
            }

            &:last-child {
                font-size: 0.75rem;
                color: var(--grey4);
            }
        }

        .coverImage {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .check-box-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 0.5rem;
        margin-bottom: 4rem;

        a {
            color: var(--hti-primary-dark) !important;
            text-decoration: underline;
        }

        .ant-checkbox-wrapper {
            width: fit-content;
            margin-left: 0;

            .ant-checkbox-inner {
                border-radius: 0.25rem;
            }

            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: var(--primary);
                    border: 1px solid var(--primary);
                }

                &:after {
                    border: 1px solid var(--primary);
                    border-radius: 0.25rem;
                }
            }
        }
    }

    .acceptButton {
        border: none;
        width: 100%;
        width: 100%;
        height: 48px;
        border-radius: 30px;
        font-size: 1.25rem;
        font-weight: 600;
        background: var(--primary);
        color: #fff;

        &:hover,
        &:focus {
            color: #fff;
            background-color: var(--primary);
        }

        &:disabled {
            opacity: 1;
            border-color: transparent;
            background-color: #e0e0e0 !important;
            color: #828282 !important;
            cursor: not-allowed;
        }
    }
}
