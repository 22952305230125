.term-of-service-container {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    margin: 3rem auto;
    padding: 0 1rem;
    max-width: 1024px;
    box-sizing: content-box;

    @media (max-width: 768.98px) {
        flex-direction: column;
        gap: 0;
    }

    .side-menu-box {
        // .ant-affix {
        //     z-index: -1;
        // }

        @media (max-width: 768.98px) {
            .ant-affix {
                position: static !important;
            }
        }
    }

    .side-menu-box > div > div {
        width: 100%;
        max-width: 224px;
        height: fit-content !important;
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: var(--grey6);

        @media (max-width: 768.98px) {
            max-width: 100%;
            margin-bottom: 2rem;
        }

        h3 {
            color: var(--title);
            font-size: 1rem;
            margin-bottom: 0;
        }

        ul {
            margin-bottom: 0;
        }

        a {
            transition: all 1s;
            color: var(--title) !important;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .content-container {
        width: 100%;
        max-width: 600px;
        position: relative;
    }

    .personal-rights-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
            width: 100%;
            margin-bottom: 2.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        h1 {
            font-size: clamp(1.25rem, 1.0333rem + 0.9245vw, 1.625rem);
            margin-bottom: 2.75rem;
            color: var(--title);
        }

        h4 {
            font-size: 1rem;
            text-align: center;
            margin-bottom: 1.25rem;
            color: var(--title);
        }

        .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            span {
                color: var(--title);
            }
        }

        .input-row {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            margin-bottom: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            input {
                border: none;
                background-color: var(--grey6);
                height: 2.5rem;
                border-radius: 0.5rem;
            }

            textarea {
                border-radius: 0.5rem;
                background-color: var(--grey6);
                border: none;
            }

            label.error {
                color: var(--error);
            }
        }

        .ant-checkbox-wrapper {
            width: fit-content;

            .ant-checkbox-inner {
                border-radius: 0.25rem;
            }

            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: var(--primary);
                    border: 1px solid var(--primary);
                }

                &:after {
                    border: 1px solid var(--primary);
                    border-radius: 0.25rem;
                }
            }
        }

        .upload-container {
            .upload-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2,
                p {
                    margin-bottom: 0;
                    color: var(--title);
                }
            }

            button.primary {
                background-color: var(--primary);
                color: #fff;
                border: none;
            }
        }

        .notice-container {
            color: var(--title);
            background-color: var(--grey6);
            border-radius: 0.5rem;
            padding: 0.625rem;

            p {
                margin-bottom: 0;
            }
        }

        button.submit {
            width: 100%;
            max-width: 400px;
            height: 64px;
            background: var(--primary);
            border: none;
            color: #fff;
            font-size: 1.25rem;
            margin-bottom: 2.5rem;
        }
    }

    .notification-measures {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        h1 {
            font-size: clamp(1.25rem, 1.0333rem + 0.9245vw, 1.625rem);
            margin-bottom: 1rem;
            text-align: left;
            color: var(--title);
        }

        h4 {
            font-size: 1rem;
            margin-bottom: 1rem;
            font-weight: 600;
            color: var(--title);
        }

        p {
            margin-bottom: 0;
            color: var(--title);
        }

        ul {
            color: var(--title);
        }

        .link {
            text-decoration: underline;
            color: #012875;
            cursor: pointer;
        }
    }
    .term-of-use-container,
    .privacy-container {
        display: flex;
        flex-direction: column;
        gap: 3.5rem;

        h1 {
            font-size: clamp(1.25rem, 1.0333rem + 0.9245vw, 1.625rem);
            margin-bottom: 1rem;
            text-align: left;
            line-height: 1;
            color: var(--title);
        }

        p {
            margin-bottom: 0;
            color: var(--title);
        }

        ul,
        li {
            margin-bottom: 0;
            color: var(--title);
        }
    }
}
