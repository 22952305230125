.delete-account-modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  h2 {
    width: 100%;
    text-align: center;
    font-weight: 500;
    margin-bottom: 0;
  }

  .ant-input-affix-wrapper {
    border-radius: 0.5rem;
    height: 3rem;
  }

  .text {
    color: var(--error) !important;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0;
  }

  .button-contain {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .cancel-button {
    border-color: var(--primary);
    color: var(--primary);
    font-weight: 500;
  }
}
