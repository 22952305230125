.verifyOTPContainer {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 2rem;
    color: var(--hti-primary-light) !important;
    margin-bottom: 0;
    text-align: center;
  }

  p.referenceCode {
    font-size: 0.875rem;
    color: var(--grey4) !important;
    margin-top: 0;
    text-align: center;
    font-weight: 400;
  }

  p.requestOTP {
    font-size: 0.875rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0;
    text-align: center;
    color: #007aff !important;
    text-decoration: underline;
    cursor: pointer;
  }

  p.expireTime {
    color: var(--hti-primary-light) !important;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 0;
    text-align: center;
  }

  .errorField {
    max-height: 100px;
    min-height: 64px;
    display: flex;
    align-items: center;
    margin: 1rem 0;

    > div {
      width: 100%;
      height: 100%;
      background: #ffcdd2;
      padding: 1.5rem 1rem;
      color: var(--error);
      border-radius: 0.5rem;
      text-align: center;
    }
  }

  .otpInput {
    margin-right: 0.5rem;
    gap: 8px;

    &:last-child {
      margin-right: 0;
    }

    input {
      width: 100% !important;
      height: clamp(50px, 10vw, 70px);
      border: none;
      background: #f2f2f2;
      border-radius: 0.5rem;
      font-size: 2.5rem;
      font-size: clamp(2rem, 2vw, 2.5rem);
      color: var(--primary);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .buttonContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    span.back {
      text-align: center;
      width: fit-content;
      color: var(--grey3);
      cursor: pointer;
    }
  }
}

.loginContainer {
  width: 100%;

  h1 {
    color: var(--hti-primary-light) !important;
    margin-bottom: 0;
  }

  .inputItem {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0 1rem;
    }
  }

  .register,
  .forgotPassword {
    color: var(--grey3);
    cursor: pointer;
  }

  .errorField {
    height: 64px;
    display: flex;
    align-items: center;
    margin: 1rem 0;

    > div {
      width: 100%;
      height: 100%;
      background: #ffcdd2;
      padding: 1.5rem 1rem;
      color: var(--error);
      border-radius: 0.5rem;
      text-align: center;
    }
  }

  .buttonContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .register {
      color: var(--grey3);
    }

    p {
      text-align: center;
      width: fit-content;
    }
  }
}

.forgotPasswordContainer {
  width: 100%;

  h1 {
    color: var(--hti-primary-light) !important;
    margin-bottom: 0;
  }

  .inputItem {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .errorField {
    height: 64px;
    display: flex;
    align-items: center;
    margin: 1rem 0;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: #ffcdd2;
      padding: 1.5rem 1rem;
      color: var(--error);
      border-radius: 0.5rem;
      text-align: center;
    }
  }

  .buttonContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    span.back {
      text-align: center;
      width: fit-content;
      color: var(--grey3);
      cursor: pointer;
    }
  }
}

.registerContainer {
  width: 100%;

  h1 {
    color: var(--hti-primary-light) !important;
    margin-bottom: 1rem;
  }

  .clientCard {
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 1px solid #bdbdbd;
    padding: 0.5rem;
    border-radius: 3rem;

    p {
      margin-bottom: 0;

      &:first-child {
        font-size: 1rem;
      }

      &:last-child {
        font-size: 0.75rem;
        color: var(--grey4);
      }
    }

    .coverImage {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .inputItem {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    > div {
      padding: 0 1rem;

      p.registerType {
        color: var(--grey4);
        cursor: pointer;
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }
  }

  .errorField {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    background: #ffcdd2;
    border-radius: 0.5rem;
    padding: 0.5rem;

    > p {
      text-align: center;
      margin-bottom: 0;
      color: var(--error) !important;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .buttonContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    span.back {
      text-align: center;
      width: fit-content;
      color: var(--grey3);
      cursor: pointer;
    }
  }

  .domainText {
    color: var(--primary-variant);
  }
}

.registerFormContainer {
  width: 100%;

  h1 {
    color: var(--hti-primary-light) !important;
    margin-bottom: 0;
  }

  .inputItem {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    > div {
      padding: 0 1rem;

      p.registerType {
        color: var(--grey4);
        cursor: pointer;
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }
  }

  .buttonContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
  }
}

.changePasswordContainer {
  width: 100%;

  h1 {
    color: var(--hti-primary-light) !important;
    margin-bottom: 0;
  }

  .inputItem {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    > div {
      padding: 0 1rem;

      p.registerType {
        color: var(--grey4);
        cursor: pointer;
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
}

.newPasswordContainer {
  width: 100%;

  h1 {
    color: var(--hti-primary-light) !important;
    margin-bottom: 0;
  }

  .inputItem {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    > div {
      padding: 0 1rem;

      p.registerType {
        color: var(--grey4);
        cursor: pointer;
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }
  }

  .errorField {
    height: 64px;
    display: flex;
    align-items: center;
    margin: 1rem 0;

    > div {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: #ffcdd2;
      padding: 1.5rem 1rem;
      color: var(--error);
      border-radius: 0.5rem;
      text-align: center;
    }
  }

  .buttonContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;

    span.back {
      text-align: center;
      width: fit-content;
      color: var(--grey3);
      cursor: pointer;
    }
  }
}

.OTP-wrong {
  width: 100%;
  .ant-modal-content .ant-modal-body h2 {
    color: var(--secondary);
    font-weight: 600;
    font-size: 20px;
    margin: 0px;
  }
  p {
    color: var(--grey1);
  }
  .anticon {
    display: none;
  }
  .otp-img-alert {
    width: 90px;
    height: 90px;
  }
  .OTP-wrong-content {
    margin: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .text-alert {
    text-align: center;
  }

  .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
    text-align: center;
    margin-top: 0px;
    .ant-btn {
      color: white;
      background-color: #d7042b;
      border: none;

      &:hover,
      &:focus {
        background-color: #d7042b;
        filter: brightness(1.2);
      }
    }
  }
}
