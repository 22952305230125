* {
    font-family: Prompt !important;
}

$font-head: 'Prompt', tahoma, sans-serif;
$font-body: 'Bai Jamjuree', tahoma, sans-serif;
$font-head-size: 14px;
$font-size: 12px;

$hti-primary-color: var(--hti-primary-light); //#012875;
$hti-primary-dark-color: var(--hti-primary-dark); //#012875;
$hti-primary-variant-color: var(--hti-primary-variant); //#001b51;
$hti-secondary-color: var(--hti-secondary); //#fd6b53;
$hti-secondary-variant-color: var(--hti-secondary-variant); //#ff5337;
$surface-color: var(--surface);
$border: 1px solid rgba(0, 0, 0, 0.125);
$noborder: 0px solid rgba(0, 0, 0, 0.125);

$primary-color: var(--primary);
$primary-dark-color: var(--primary-dark);
$primary-variant-color: var(--primary-variant);
$secondary-color: var(--secondary);
$secondary-variant-color: var(--secondary-variant);
$title-color: var(--title);
$header-color: var(--header);
$body-color: var(--body);
$modalBackground: var(--modal-background);

$onprimary-color: var(--on-primary);
$onprimary-dark-color: var(--on-primary-dark);
$onsecondary-color: #ffffff;
$onsurface-color: var(--on-surface);
$onerror-color: #ffffff;

$grey1-color: var(--grey1);
$grey2-color: var(--grey2);
$grey3-color: var(--grey3);
$grey4-color: var(--grey4);
$grey5-color: var(--grey5);
$grey6-color: var(--grey6);
$grey7-color: var(--grey7);

$error-color: #ec6b61; //#e55050;
$complete-color: #5cb85c;
$warning-color: #eb9d18; //#ffb914;
$disable-color: #cccccc;
$button-color: #1890ff;
$onbutton-color: #ffffff;
$disable-button-color: #f5f5f5;

$max-width: 1280px;
$min-width: 375px;

$min-width-lg: 1200px;
$min-width-md: 992px;
$min-width-sm: 768px;

$max-width-md: 1199px;
$max-width-sm: 991px;
$max-width-xs: 767px;

$minspace: 8px;
$space: 16px;
$space2: 32px;
$cover-height: 200px;
$list-cover-height: 170px;
$cover-ratio: 0.68; //0.75;
$cover-video-ratio: 16/9;
$cover-podcast-ratio: 1;
$book-title-writer-height: 70px; //52px;
$banner-ratio: 2.5; //0.75;

$header-height: 105px;
$headerSmall-height: 180px;
$footer-height: 180px;
$footerSmall-height: 210px;
$bc-height: 39px;
$bcwithmargin-height: calc(39px + 16px);
$headtext-height: calc(60px + 32px);

body {
    overscroll-behavior: none;
    scroll-behavior: smooth;
    min-width: 414px;
    background-color: $surface-color;
    transition: all 0.5s;
}

.ant-breadcrumb-link {
    color: var(--on-surface);
}

.ant-modal-confirm-title {
    color: $title-color !important;
}

.ant-modal-confirm-content {
    color: var(--on-surface) !important;
}

.ant-modal-close-x {
    color: $grey5-color;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

//! ถ้าไม่มีปัญหาลบได้
.flex {
    display: flex;

    &.between {
        justify-content: space-between;
    }

    &.center {
        justify-content: center;
    }

    &.align-center {
        align-items: center;
    }
}

.cu-mobile-bg {
    background-color: white !important;
}

.mobile-view {
    background-color: $primary-color;
    width: 100%;
    height: 100%;
    position: absolute;

    .hibrary-logo {
        max-width: 130px;
        top: -300px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .by-hibrary-logo {
        position: absolute;
        bottom: 20px;
        transform: translate(-50%, -50%);
        left: 50%;
    }

    .hibrary-text {
        top: 48%;
        left: 50%;
        -ms-transform: translate(-50%, -48%);
        transform: translate(-50%, -48%);
        position: relative !important;
        font-size: 20px;
        p {
            font-family: 'Prompt';
            margin-bottom: 0px;
        }
        color: white;
        font-weight: 300;
        text-align: center;
    }

    .hibrary-text.top48 {
        -ms-transform: translate(-50%, -48%);
        transform: translate(-50%, -48%);
    }

    .hibrary-text.top49 {
        -ms-transform: translate(-50%, -49%);
        transform: translate(-50%, -49%);
    }

    .download-app {
        top: 50px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .download-browser {
        background-color: white;
        padding: 10px;
        border-radius: 30px;
        max-width: 110px;
        top: 290px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .hibrary-logo,
    .download-app,
    .hibrary-text,
    .download-browser {
        max-height: 100%;
        max-width: 200px;
        width: auto;
        height: auto;
        position: absolute;
        margin: auto;
    }

    .download-app {
        image-rendering: -webkit-optimize-contrast;
    }

    .hibrary-text.browser-text {
        margin-top: 60px;
    }

    .browsers-row {
        height: 120px;
        max-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        margin: auto;
        top: 40px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.not-found {
    image-rendering: -webkit-optimize-contrast;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@mixin setDefaultHeightBasePage($hasBreadcrumb: 'false') {
    // h - nav - footer
    @if ($hasBreadcrumb == 'true') {
        min-height: calc(
            100vh - #{$header-height} - #{$footer-height} - #{$bcwithmargin-height} + 16px
        );
        @media (max-width: 600px) {
            min-height: calc(
                100vh - #{$headerSmall-height} - #{$footerSmall-height} - #{$bcwithmargin-height} + 16px
            );
        }
    } @else {
        min-height: calc(100vh - #{$header-height} - #{$footer-height}); // - 156px);
        @media (max-width: 600px) {
            min-height: calc(100vh - #{$headerSmall-height} - #{$footerSmall-height});
        }
    }
}

@mixin setHeader {
    @include setHeaderBold(400);
}

@mixin setHeaderBold($weight) {
    font-family: $font-head !important;
    font-weight: $weight !important;
}

@mixin setWidth {
    min-width: $min-width;
    max-width: $max-width;
}

@mixin setWordwrap($line) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line; /* number of lines to show */
    -webkit-box-orient: vertical;
}

@mixin setButtonSize($w, $h) {
    height: $h;
    padding: 0;
    line-height: $h;
    width: $w;
    @include setHeader;
}

@mixin setHeightSlider($w, $num) {
    height: calc((#{$w} / #{$num} / #{$cover-ratio}) + #{$book-title-writer-height});
}

@mixin setVideoHeightSlider($w, $num) {
    height: calc((#{$w} / #{$num} / #{$cover-video-ratio}) + #{$book-title-writer-height});
}
@mixin setNoHighlight() {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin setShadow() {
    // box-shadow: 4px 4px 8px #ccc;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}
@mixin unsetShadow() {
    box-shadow: unset;
    filter: unset;
}

h1 {
    @include setHeaderBold(600);
    text-align: center;
}

.base-page {
    width: 100%;
    position: relative;
    padding-top: #{$header-height};
    min-height: 100vh;

    @media (max-width: 768.98px) {
        padding-top: #{$headerSmall-height};
    }
}

.dropdown-text {
    font-family: Prompt;
    margin-bottom: 0px;
    margin-left: 5px;
}

.dropdown-text.no-pic {
    margin-left: 0;
}

.auth-page {
    height: 100vh;
    padding: 80px;
    width: 600px;
    margin: auto;
}

.user-view {
    height: $header-height;
}

.cursor-pointer {
    cursor: pointer;
}

.spinnerView {
    height: calc(100vh - #{$header-height} - #{$footer-height});
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
        height: calc(100vh - #{$headerSmall-height} - #{$footerSmall-height});
    }

    > div {
        padding: 0px !important;
    }
}

.store,
.bookShelf,
.categories {
    min-height: calc((var(--vh, 1vh) * 100) - #{$footer-height});
    margin-bottom: 3rem;
}

.qNh,
.profile-page {
    @include setDefaultHeightBasePage();
    padding: 3rem;
}

.nodata {
    height: calc(100vh - #{$header-height} - #{$footer-height} - #{$bc-height});
    @media (max-width: 600px) {
        height: calc(100vh - #{$headerSmall-height} - #{$footerSmall-height} - #{$bc-height});
    }
    min-height: 50px;
    text-align: center;
    width: 100%;
    display: flex;
    // height: 100%;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    p {
        @include setHeader();
        font-size: 20px;
        color: $title-color;
    }
}

.disable-user-selection {
    -webkit-user-select: none;
}

.gotoTop {
    cursor: pointer;
    image-rendering: -webkit-optimize-contrast;
    outline: none;
    z-index: 99;
    position: fixed;
    width: 48px;
    border-radius: 2px;
    bottom: 2rem;
    right: 1rem;
    font-family: Prompt;
    font-size: 18px;
    color: black;
    border: none;

    &.isClose {
        animation: hide 0.5s ease-out forwards;
    }

    &.isShow {
        animation: show 0.5s ease-in forwards;
    }

    @keyframes show {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
            display: block;
        }
    }

    @keyframes hide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
            display: none;
            z-index: -99;
        }
    }

    @media only screen and (max-width: 576.98px) {
        bottom: 1rem;
        width: 40px;
    }
}
