.sponsorModal {
    .ant-modal-body {
        display: flex;
        align-items: center;
        gap: 2rem;
        position: relative;
        min-height: 20vh;

        @media (max-width: 1024.98px) {
            flex-direction: column;
        }
    }

    .ant-divider {
        border: 1px solid var(--grey2);
    }

    .in-component-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .coverImageContainer {
        width: 30%;

        @media (max-width: 1024.98px) {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .coverImage {
            width: 250px;
            height: 250px;
            display: flex;

            img {
                width: 100%;
                height: auto;
                border-radius: 50%;
                filter: drop-shadow(rgba(0, 0, 0, 0.09) 0px 3px 12px);
            }
        }
    }

    .descriptionContainer {
        width: 70%;

        @media (max-width: 1024.98px) {
            width: 100%;
        }

        p {
            margin-bottom: 0;
        }

        .addressData {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            @media (max-width: 1024.98px) {
                justify-content: space-around;
                gap: 1rem;
            }

            @media (max-width: 576.98px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .ant-space-item {
            display: flex;
            align-items: center;
        }

        .title {
            font-size: 1.75rem;
            color: var(--grey1);
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding-right: 1rem;
        }

        p.description {
            font-size: 0.875rem;
            color: var(--grey2) !important;
            margin-bottom: 2rem;
        }

        p.text {
            color: var(--grey2) !important;
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .iconContainer {
            svg {
                transition: all 0.3s;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.05);
                }
            }
        }
    }
}
