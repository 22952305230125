.successSubjectRightModal {
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        height: 200px;
    }

    h1 {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 32px; /* 160% */
    }
}
