.sponsorSetContainer {
   width: 100%;
   max-width: calc(1280px + 32px);
   margin: 0 auto;
   padding: 4rem 1rem;
   position: relative;

   @media (max-width: 1024.98px) {
      padding: 2rem 1rem;
   }

   h1 {
      font-size: clamp(1rem, 5vw, 2rem);
      color: var(--title);
      text-transform: capitalize;
      margin-bottom: 2rem;
   }

   .contentContainer {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      align-items: baseline;

      @media (max-width: 1024.98px) {
         grid-template-columns: repeat(5, 1fr);
      }

      @media (max-width: 768.98px) {
         grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: 576.98px) {
         grid-template-columns: repeat(3, 1fr);
      }
   }
}
