.loginModal {
    .ant-modal-body {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
        height: fit-content;
    }

    .ant-modal-content {
        width: 100%;
    }

    img {
        width: 150px;
        height: auto;
        border-radius: 50%;
    }

    .infoText {
        color: var(--primary-variant);
    }

    .formInput {
        margin-top: 16px;
        width: 100%;
        padding: 0 16px;
        border: none;
        border-radius: 22px;
        height: 44px;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        box-shadow: none;

        &::placeholder {
            color: var(--grey5);
        }

        &:disabled {
            color: var(--grey4);
        }

        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        .tooltip-inner {
            display: flex;
            align-items: center;
        }

        .ant-input {
            background: #f2f2f2;

            &:disabled {
                color: var(--grey4);
            }

            &::placeholder {
                color: var(--grey5);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focu {
                -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
                transition: background-color 5000s ease-in-out 0s;
            }
        }

        .ant-input-group {
            .ant-input-group-addon {
                border: none;
                background-color: #f2f2f2;
            }

            .ant-input {
                border: none;
                background-color: #f2f2f2;
                box-shadow: none;
            }
        }
    }

    .check-box-container {
        margin-top: 0.5rem;
        a {
            color: var(--hti-primary-dark) !important;
            text-decoration: underline;
        }

        .ant-checkbox-wrapper {
            width: fit-content;

            .ant-checkbox-inner {
                border-radius: 0.25rem;
            }

            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: var(--primary);
                    border: 1px solid var(--primary);
                }

                &:after {
                    border: 1px solid var(--primary);
                    border-radius: 0.25rem;
                }
            }
        }
    }

    .acceptButton {
        border: none;
        width: 100%;
        width: 100%;
        height: 48px;
        border-radius: 30px;
        font-size: 1.25rem;
        font-weight: 600;
        background: var(--primary);
        color: #fff;

        &:hover,
        &:focus {
            color: #fff;
            background-color: var(--primary);
        }

        &:disabled {
            opacity: 1;
            border-color: transparent;
            background-color: #e0e0e0 !important;
            color: #828282 !important;
            cursor: not-allowed;
        }
    }

    .error-text {
        text-align: left;
        color: #f64e60 !important;
        font-size: 0.875rem;
        font-weight: 400;
    }
}
