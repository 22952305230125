.list-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 1280px;
    margin: 0 auto;
}

.header-title {
    margin-bottom: 2rem;
}

.card-of-ebook {
    width: 100%;
    max-width: 240px;
    cursor: pointer;
    border-radius: 0.5rem;
    text-align: center;
    color: #ffffff;
    padding: 1rem;
    // -webkit-box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    // -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));

    img {
        height: 150px;
        margin: 20px auto;
    }

    .label {
        font-size: 18px;
        font-weight: 500;
    }

    &.card-publisher {
        background-color: rgb(45, 156, 219);
    }

    &.card-cat {
        background-color: rgb(251, 192, 32);
    }

    &.card-newest {
        background-color: rgb(33, 150, 83);
    }
}

.col-publisher {
    border-radius: 10px;
    box-shadow: rgb(115 115 115 / 30%) 0px 0px 4px;
    cursor: pointer;
    height: 95px;
    display: flex;
    align-items: center;

    img {
        width: 62px;
        image-rendering: -webkit-optimize-contrast;
        margin: auto 0px auto 8px;
        border-radius: 50%;
    }

    span {
        margin: auto 0px auto 8px;
        font-family: 'Prompt';
    }
}

.categoryContainer {
    margin-top: 1rem;
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 3rem;
    padding: 0 1rem;
    box-sizing: content-box;

    .categoryContentList {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        gap: 1rem;
    }

    .categoryCover {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        opacity: 0;

        .cover {
            display: flex;
            align-items: center;
            max-width: -webkit-fill-available;

            .cover {
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(100% - (0.5rem * 5));
                transition: transform 0.5s;
                max-width: -webkit-fill-available;

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s;
                }

                img.ebook {
                    width: auto;
                    height: 100%;
                    max-height: 170px;
                }

                img.video {
                    width: 100%;
                    height: auto;
                    max-height: 170px;
                }
            }

            img.ebook {
                width: auto;
                height: 100%;
                max-height: 170px;
            }

            img.video {
                width: 100%;
                height: auto;
                max-height: 170px;
            }
        }
    }

    .fadeIn {
        animation: fadeIn 0.5s ease-in-out 0s 1 both;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.ebookContainer {
    max-width: 1280px;
    margin: 0 auto;
    box-sizing: content-box;
    padding: 0 1rem;
    margin-bottom: 3rem;

    .contentsContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 1rem;

        .col-ebook-detail {
            .detail {
                color: var(--grey4);
            }

            .title {
                color: var(--);
            }
        }
    }

    .col-ebook {
        display: flex;
        gap: 16px;
        position: relative;

        img {
            width: 100%;
            max-width: 150px;
            height: auto;
            max-height: 200px;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));

            &:hover {
                cursor: pointer;
            }
        }

        .detail {
            font-size: 12px;
        }

        .book-title {
            font-weight: 500;
            font-size: 1rem;
            margin-bottom: 0.5rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: var(--title);

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.publisherContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: content-box;
}
