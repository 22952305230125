.device-list {
   min-height: 200px;

   .divider {
      margin: 8px 0;
      border-color: var(--grey6);
   }

   .device-item {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      color: var(--hti-primary-light);

      svg:hover {
         cursor: pointer;
      }

      .device-information {
         flex: 2;

         h3 {
            font-size: 16px;
            margin: 0;
         }

         p {
            font-size: 12px;
            font-family: Prompt;
         }
      }
   }

   .nodata-view {
      max-height: 400px !important;
   }
}
