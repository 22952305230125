.privacy-policy-modal {
    .ant-modal-body {
        padding: 1rem;
    }

    .title-container {
        h1 {
            font-size: 2rem;
            color: var(--hti-primary-light) !important;
            margin-bottom: 0.5rem;
            line-height: 1;
        }

        p {
            margin-bottom: 1rem;
            color: #007aff !important;
            text-align: center;
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding: 1rem;
        height: 60vh;
        overflow: auto;
        border-radius: 0.5rem;
        border: 1px solid var(--grey6);

        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track-piece {
            width: 10px;
            background-color: #fff !important;
            border-radius: 16px;
        }

        &::-webkit-scrollbar-thumb {
            background: #bdbdbd;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #a0a0a0;
        }

        h1 {
            font-size: 1.625rem;
            color: var(--hti-primary-light) !important;
            margin-bottom: 1rem;
            text-align: left;
            line-height: 1;
        }

        p {
            margin-bottom: 0;
            color: var(--title) !important;
        }

        ul {
            margin-bottom: 0;
            padding-inline-start: 1.5rem !important;
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        padding-top: 1rem;

        button {
            background-color: var(--primary);
            border: none;
            width: 200px;
            height: 3rem;
            color: #fff;

            &:disabled {
                cursor: not-allowed;
                background-color: #e0e0e0;
                color: #898989;
            }
        }
    }
}
