.breadcrumb {
    min-width: 375px;
    max-width: 1280px;
    margin: 16px auto;

    @media only screen and (max-width: 1300px) {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .ant-breadcrumb {
        li span a {
            color: var(--grey2);

            &:hover {
                color: var(--primary);
            }
        }
    }
}
