.ant-modal-confirm-body-wrapper {
    .ant-modal-confirm-btns {
        button {
            min-width: 100px;
            border-radius: 25px;
        }
    }
}
.ant-modal-content {
    background-color: var(--modal-background);
    .ant-modal-header {
        background-color: inherit;
        border-radius: 10px 10px 0 0;
        .ant-modal-title {
            font-size: 20px;
            font-weight: 600;
            font-family: Prompt;
            color: var(--title);
        }
    }
    .ant-modal-body {
        h3,
        h1,
        h2 {
            color: var(--title);
        }
        p {
            color: var(--on-surface);
        }
    }
}

.base-page {
    .categories {
        h1 {
            margin-bottom: 64px;
        }
    }

    .emptyView {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

// .swiper-container {
//     height: fit-content;
// }

.swiper-wrapper {
    height: auto !important;
}

.ant-dropdown-menu-title-content {
    font-family: Prompt;
}

.passage {
    margin-top: 32px;
    padding: 0px 32px;
}

.ant-modal-confirm-content {
    font-family: Bai Jamjuree;
}

.hidden {
    display: none;
}

.trial-store {
    .ant-modal-confirm-btns {
        text-align: center;
    }
}
