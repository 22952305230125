//! ถ้าไม่ใช้ละลบด้วย
// .categorySwiper {
//     margin-top: 16px;

//     .swiper-wrapper {
//         align-items: flex-end;
//     }

//     .category {
//         display: flex;
//         flex-direction: column;
//         align-content: center;
//         justify-content: center;
//     }
// }

//! ถ้าไม่ใช้ละลบด้วย
// .bestSeller {
//     .swiper-wrapper {
//         align-items: flex-start;
//     }

//     .rank {
//         font-family: 'Prompt';
//         margin-top: 8px;

//         .titleNumber {
//             font-family: inherit;
//             font-weight: 500;
//             font-size: 12px;
//         }
//         .queueNumber {
//             font-family: inherit;
//             font-weight: 400 !important;
//             font-size: 16px;
//             font-weight: 600;
//             color: #fd6b53;
//             line-height: 1em;
//         }
//     }
// }

.content-collection-bestseller {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    height: 120px;

    .total-time {
        display: none;
    }

    .rankBestseller {
        font-style: normal;
        font-weight: 500;
        font-size: 38px;
        color: var(--hti-primary-light);
        width: 20px;
        margin-right: 25px;
        line-height: 30px;
    }

    > :last-child {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .cover {
        height: 100% !important;
        margin-top: 0 !important;
        -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1)) !important;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1)) !important;
        border-radius: 2px;
        width: 70px;
    }

    .bookTitleContainer {
        margin-top: 0;
        margin-bottom: 8px;
        max-width: 250px;

        .title {
            line-height: 1.2;
            height: 1.2em;
            -webkit-line-clamp: 1;
            color: #012875;
        }
    }
}

.content-collection {
    max-width: 400px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.5rem;

    .image-cover {
        max-width: none;

        &.podcast,
        &.video {
            width: 100%;
        }
    }

    // .spanLink {
    //     font-family: 'Prompt', tahoma, sans-serif !important;
    //     display: -webkit-box;
    //     -webkit-line-clamp: 1;
    //     -webkit-box-orient: vertical;
    //     overflow: hidden;
    //     color: var(--grey4);
    // }

    .total-time {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 10px;
        padding: 4px;
        color: white;
        margin: 0 8px 8px 0;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .bookTitleContainer {
        height: 70px;

        .bookTitle {
            width: fit-content;
            font-size: clamp(0.75rem, 0.7197rem + 0.1294vw, 0.875rem);
            font-weight: 500;
            margin-bottom: 0;
            color: var(--header);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            cursor: pointer;
            line-height: 1.6;
            transform: all 0.3s;

            &:hover {
                text-decoration: underline;
            }
        }

        .detail {
            width: 100%;
            font-size: clamp(0.625rem, 0.5947rem + 0.1294vw, 0.75rem);
            font-weight: 400;
            margin-bottom: 0;
            line-height: 1.6;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            transform: all 0.3s;

            span,
            a {
                color: var(--sub-header);

                &:hover {
                    text-decoration: underline;
                }
            }

            .boxUploadDate {
                margin-top: 0.5rem;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .cover {
            width: 100%;
        }

        .video-cover {
            width: 100%;
        }

        .podcast-cover {
            width: 100%;
        }
    }
}

.col-video .content-collection {
    max-width: calc((768px - (16px * 3) - 10px) / 2);
    @media (max-width: 768px) {
        width: calc((100vw - (16px * 3) - 10px) / 2);
    }
    @media (max-width: 540px) {
        width: calc((100vw - (16px * 3) - 10px) / 2);
    }
    @media (max-width: 414px) {
        width: calc((100vw - (16px * 2) - 10px) / 1);
    }
    @media (max-width: 375px) {
        width: calc((375px - (16px * 2) - 10px) / 1);
    }
}

.col-podcast .content-collection {
    max-width: 300px;
    @media (max-width: 768px) {
        width: calc((100vw - (16px * 4) - 10px) / 3);
    }
    @media (max-width: 540px) {
        width: calc((100vw - (16px * 3) - 10px) / 2);
    }
    @media (max-width: 375px) {
        width: calc((375px - (16px * 3) - 10px) / 2);
    }
}

.sponsorStoreHome {
    .itemContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: fit-content;
        margin: 0;
    }

    .coverImage {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        overflow: hidden;
        box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        margin: 0 auto;
        margin-bottom: 1rem;

        img {
            width: 100%;
            height: 100%;
            transition: all 0.3s;
            object-fit: cover;
        }

        &:hover {
            img {
                // transform: scale(1.05);
                cursor: pointer;
            }
        }
    }

    p {
        margin-bottom: 0;
        color: var(--grey3);
        text-align: center;
        font-weight: 500;
    }
}
