.viewAllSponsorBanner {
   width: 100%;
   margin: 0 auto;
   max-width: 1280px;
   padding: 4rem 1rem;
   min-height: calc(100vh - 105px);
   position: relative;

   h1 {
      font-size: clamp(1rem, 5vw, 2rem);
      color: var(--title);
      text-transform: capitalize;
      margin-bottom: 2rem;
   }

   .contentContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 1rem;

      .sponsorBannerCover {
         width: 100%;

         img {
            width: 100%;
            height: auto;
            border-radius: 0.5rem;
            box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px,
               rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            transition: all 0.3s;
            max-height: 200px;
            object-fit: cover;

            &:hover {
               transform: scale(1.03) translateY(-0.5rem);
            }
         }
      }
   }
}
