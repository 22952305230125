.addNewLibraryModal {
    .ant-modal-body {
        padding: 1rem;
        padding-bottom: 0;
    }

    .error-text {
        text-align: left;
        color: #f64e60 !important;
        font-size: 0.875rem;
        font-weight: 400;
    }

    .addLibraryContainer {
        width: 100%;

        .titleSection {
            display: flex;
            align-items: center;
            // justify-content: center;
            // position: relative;
            // height: 2rem;

            h1 {
                font-size: 2rem;
                color: var(--hti-primary-light) !important;
                margin-bottom: 0;
                align-self: center;
                margin: 0 auto;
            }

            svg {
                color: var(--primary);
                font-weight: 700;
                cursor: pointer;
            }
        }

        .clientListContainer {
            overflow: auto;
            height: 500px;
            position: relative;

            &::-webkit-scrollbar {
                display: none; /* Safari and Chrome */
            }

            .clientItem {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
                padding: 1rem 0;
                border-bottom: 1px solid var(--grey5);

                &:last-child {
                    margin-bottom: 1rem;
                }

                &:first-child {
                    border-top: none;
                }

                .coverImage {
                    width: 5rem;
                    height: 5rem;
                    overflow: hidden;
                    border-radius: 50%;
                    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                .clientName {
                    color: var(--grey1);
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }

                .clientDetail {
                    font-size: 0.75rem;
                    color: var(--grey3);
                }
            }
        }
    }

    .selectLibraryContainer {
        width: 100%;

        h1 {
            font-size: 2rem;
            color: var(--hti-primary-light) !important;
            margin-bottom: 0;
            text-align: center;
        }

        .clientListContainer {
            overflow: auto;
            height: 500px;
            position: relative;

            &::-webkit-scrollbar {
                display: none; /* Safari and Chrome */
            }

            .clientItem {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
                padding: 1rem 0;
                border-bottom: 1px solid var(--grey5);

                &:last-child {
                    margin-bottom: 1rem;
                }

                .coverImage {
                    width: 5rem;
                    height: 5rem;
                    overflow: hidden;
                    border-radius: 50%;
                    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                .clientName {
                    color: var(--grey1);
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }

                .clientDetail {
                    font-size: 0.75rem;
                    color: var(--grey3);
                }
            }

            svg {
                color: var(--hti-primary-light);
                transition: all 0.5s;

                &:hover {
                    color: var(--secondary);
                    transform: scale(1.05);
                    cursor: pointer;
                }
            }
        }
    }

    .addLibraryFormContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        form {
            width: 100%;
        }

        h1 {
            font-size: 2rem;
            color: var(--hti-primary-light) !important;
            align-self: center;
            margin: 0 auto;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        img {
            width: 150px;
            height: 150px;
        }

        .formContainer {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;

            .inputItem {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;

                > div {
                    padding: 0 1rem;

                    p.registerType {
                        color: var(--grey4);
                        cursor: pointer;
                        margin-top: 0.5rem;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .selectLibraryButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        padding-top: 1rem;

        button {
            border: 1px dashed var(--hti-primary-light);
            color: var(--hti-primary-light);

            &:hover,
            &:focus {
                border: 1px dashed var(--secondary);
                color: var(--secondary);
            }
        }
    }

    .successAddLibrary {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        padding-bottom: 2rem;
        height: 250px;

        > div {
            text-align: center;
        }

        img {
            width: 75px;
            height: 56px;
        }

        h1 {
            font-size: 20px;
            font-weight: 600;
            line-height: 32px; /* 160% */
            color: var(--grey1);
            margin-bottom: 0;
        }

        h4 {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            color: var(--grey1);
            margin-bottom: 0;
        }

        button {
            width: 150px;
            color: var(--surface);
            background: var(--hti-primary-light);
            border: none;

            &:hover,
            &:focus {
                background: var(--secondary);
                color: var(--surface);
            }
        }
    }

    .buttonContain {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 2rem;
    }

    .formInput {
        width: 100%;
        padding: 0 16px;
        border: none;
        border-radius: 22px;
        height: 44px;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        box-shadow: none;

        &::placeholder {
            color: var(--grey5);
        }

        &:disabled {
            color: var(--grey4);
        }

        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        .tooltip-inner {
            display: flex;
            align-items: center;
        }

        .ant-input {
            background: #f2f2f2;

            &:disabled {
                color: var(--grey4);
            }

            &::placeholder {
                color: var(--grey5);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focu {
                -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
                transition: background-color 5000s ease-in-out 0s;
            }
        }

        .ant-input-group {
            .ant-input-group-addon {
                border: none;
                background-color: #f2f2f2;
            }

            .ant-input {
                border: none;
                background-color: #f2f2f2;
                box-shadow: none;
            }
        }
    }

    .acceptButton {
        border: none;
        width: 100%;
        height: 48px;
        border-radius: 30px;
        font-size: 1.25rem;
        font-weight: 600;
        background: var(--primary);
        color: #fff;

        &:hover,
        &:focus {
            color: #fff;
            background-color: var(--primary);
        }

        &:disabled {
            opacity: 1;
            border-color: transparent;
            background-color: #e0e0e0 !important;
            color: #828282 !important;
            cursor: not-allowed;
        }
    }

    .backButton {
        cursor: pointer;
    }
}
