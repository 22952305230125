.login-form {
  font-family: Prompt;
  text-align: center;

  @media (max-width: 576.98px) {
    padding: 0;
  }

  .h3-head {
    font-size: 2rem;
  }

  .login-sso-button {
    color: var(--primary);
    margin-bottom: 0.5rem;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .alert-box {
    background-color: #ffe2e5;
    border-color: transparent;
    display: flex;
    align-items: stretch;
    padding: 1.5rem 2rem;
    margin-top: 20px;
    margin-bottom: -80px;
    border-radius: 6px;
  }

  .alert-box.info {
    background-color: #e6f7ff;
  }

  .alert-text {
    color: #f64e60;
    align-self: center;
    flex-grow: 1;
    font-weight: 500 !important;

    &.info {
      color: #3790ff;
    }
  }

  .alert-text .h3-head {
    font-family: Prompt;
    margin-top: 32px;
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: 500;
    color: #012875;

    @media (max-width: 576.98px) {
      font-size: 1.5rem;
    }
  }

  .infoText {
    color: (--primary-variant);
    margin-bottom: 0;
    text-align: left;
  }

  .form-input {
    font-family: Prompt;
    outline: none;
    margin-top: 16px;
    width: 100%;
    padding: 0 16px;
    border: none;
    border-radius: 22px;
    height: 44px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    box-shadow: none;

    &::placeholder {
      color: var(--grey5);
    }

    &:disabled {
      color: var(--grey4);
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .tooltip-inner {
      display: flex;
      align-items: center;
    }

    .ant-input {
      background: #f2f2f2;

      &:disabled {
        color: var(--grey4);
      }

      &::placeholder {
        color: var(--grey5);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focu {
        -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    .ant-input-group {
      .ant-input-group-addon {
        border: none;
        background-color: #f2f2f2;
      }

      .ant-input {
        border: none;
        background-color: #f2f2f2;
        box-shadow: none;
      }
    }
  }

  .accept-button {
    outline: none;
    border: none !important;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;

    @media (max-width: 576.98px) {
      height: 48px;
      font-size: 1rem;
    }

    &:disabled {
      opacity: 1;
      border-color: transparent;
      background-color: #e0e0e0 !important;
      color: #828282 !important;
      cursor: not-allowed;
    }
  }

  .under-text {
    font-family: Prompt;
    color: #828282;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    margin-top: 5px;
  }

  .under-text.center {
    justify-content: center;
  }

  .under-text.end {
    justify-content: flex-end;
  }

  .forgot-password {
    width: fit-content;
    float: right;
    margin-top: 0 !important;
  }

  .back-auth {
    width: fit-content;
    margin: 0 auto;
  }

  .under-text-regis {
    font-family: Prompt;
    font-weight: 600;
    color: #ff5337;
    font-size: 16px;
    cursor: pointer;
  }

  .error-text {
    text-align: left;
    color: #f64e60;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.div-success {
  font-family: Prompt;
  text-align: center;
  border-radius: 10px;
  background-color: var(--modal-background);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  max-height: 310px;

  .div-text {
    font-family: Prompt;
    font-size: 24px;
    margin-bottom: 26px;
    font-weight: 500;
    color: var(--hti-primary-light);
  }

  .success-button {
    border: none;
    color: white;
    margin: auto;
    width: 150px;
    height: 48px;
    border-radius: 100px;
    background-color: #012875;
    font-size: 15px;
    cursor: pointer;
  }

  form {
    flex: 2;
  }

  .lottie {
    width: 200px;
  }
}

.login-form-container {
  flex: 2;
}

.profile-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  @media (max-width: 576.98px) {
    margin: 0 1rem;
  }

  > div {
    width: 100%;
  }

  .ant-col {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-divider {
    margin: 16px 0;
    background-color: $grey3-color;
  }

  h2 {
    @include setHeaderBold(500);
    color: $title-color;
  }

  label {
    @include setHeaderBold(400);
    color: var(--grey1);
  }

  input {
    font-family: $font-head;
    border: none;
    text-align: right;
    color: var(--grey1);
    background-color: $surface-color;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  button.accept-button {
    @include setHeaderBold(400);
    text-align: center;
    display: flex;
    width: fit-content;
    border: none !important;

    &:disabled {
      background-color: $disable-button-color !important;
      color: $disable-color !important;
    }
  }

  .ant-col button {
    margin: 0;
  }
}

.search-library {
  margin: 35px auto;
  width: 80%;
  .ant-input-affix-wrapper {
    border-radius: 50px;
  }
}

.confirmRemoveModal {
  .confirmRemoveClient {
    text-align: center;

    .removeText {
      color: var(--error);
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-family: Prompt;
  }

  .accept-button {
    border: none;
  }

  .ant-input-password {
    width: 100%;
    padding: 0 16px;
    border: none;
    border-radius: 22px;
    height: 44px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    box-shadow: none;

    &::placeholder {
      color: var(--grey5);
    }

    &:disabled {
      color: var(--grey4);
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .tooltip-inner {
      display: flex;
      align-items: center;
    }

    .ant-input {
      background: #f2f2f2;

      &:disabled {
        color: var(--grey4);
      }

      &::placeholder {
        color: var(--grey5);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focu {
        -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    .ant-input-group {
      .ant-input-group-addon {
        border: none;
        background-color: #f2f2f2;
      }

      .ant-input {
        border: none;
        background-color: #f2f2f2;
        box-shadow: none;
      }
    }
  }
}

.error-message {
  color: var(--error);
  text-align: left;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.registerPhoneNumberButton {
  margin-top: 0.5rem;
  color: var(--grey4);
  text-align: left;
  cursor: pointer;
  width: fit-content;
}
