.list-bestSeller > div > div {
    margin-bottom: 32px;
}

// .contentSet-swiper {
//     max-width: 300px;
// }

.homeContainer {
    .swiper-container {
        padding: 1rem !important;
        margin: -1rem !important;
    }
}

.title-segment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    h2 {
        font-size: 1.75rem;
        font-weight: 600;
        margin-bottom: 0;
        color: var(--header);
    }

    h3 {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 0;
        color: var(--sub-header);
    }
}

.sponsorBannerCover {
    width: 100%;

    img {
        width: 100%;
        height: auto;
        border-radius: 0.5rem;
        box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transition: all 0.3s;
        max-height: 200px;
        object-fit: cover;

        &:hover {
            transform: scale(1.03) translateY(-0.5rem);
        }
    }
}
