div.splashLoading {
   height: 100vh;
   width: 100vw;
   position: fixed;
   background-color: white;
   display: flex;
   flex-wrap: nowrap;
   align-content: center;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   z-index: 10000;
   top: 0;

   .lottie {
      width: 300px;
      height: 300px;
   }

   @media (max-width: 768.98px) {
      height: 100%;
      width: 100%;
   }
}

div.animateHideLoading {
   animation: hideLoading 0.5s ease-in-out 0s 1 both;
}
div.hideLoading {
   display: none;
}
@keyframes hideLoading {
   0% {
      opacity: 1;
   }
   100% {
      opacity: 0;
      display: none;
   }
}
