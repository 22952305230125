.nodata-view {
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex: 2;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    .ant-empty-description {
        font-family: Prompt;
    }
}
