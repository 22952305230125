.cookieTab {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 4rem);
    padding: 1rem;
    background-color: var(--surface2);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 1.25rem;
    transition: 0.5s all;

    @media (max-width: 1024.98px) {
        width: calc(100vw - 1rem);
    }

    @media (max-width: 768.98px) {
        width: calc(100% - 1rem);
        flex-direction: column;
        align-items: flex-start;
    }

    &.fadeOut {
        transform: translateY(110vh) translate(-50%, 0);
        opacity: 0;
    }

    p {
        margin-bottom: 0;
        font-size: clamp(12px, 0.4083rem + 0.9245vw, 1rem);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        transition: all 0.3s;
        color: var(--title);

        @media (max-width: 768.98px) {
            display: flex;
            flex-direction: column;
        }

        a {
            width: fit-content;
            color: #66b0ff;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    p.title {
        font-size: clamp(0.875rem, 0.5333rem + 0.9245vw, 1.125rem);
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    div:last-child {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .acceptButton {
            background-color: var(--secondary);
            border: none;
            color: #fff;
            font-size: clamp(0.875rem, 0.4083rem + 0.9245vw, 1rem);
        }

        .cancelButton {
            border-color: var(--secondary);
            color: var(--secondary);
            background-color: transparent;
            font-size: clamp(0.875rem, 0.4083rem + 0.9245vw, 1rem);
        }

        @media (max-width: 768.98px) {
            flex-direction: column;
            width: 100%;

            .acceptButton,
            .cancelButton {
                width: 100%;
            }
        }
    }
}
