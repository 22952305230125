@mixin setHeightBookshelf($w, $num) {
    height: calc((#{$w} / #{$num} / #{$cover-ratio}) + 20px);
}

.bookShelf {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1.5rem;

    @media (max-width: 1024.98px) {
        padding: 0 1rem;
    }

    @media (max-width: 768.98px) {
        padding: 0 0.625rem;
    }

    .bookShelf-header {
        h1 {
            margin-bottom: 0;
            padding-top: 0;
        }

        .header {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }
    }

    .bookshelf-content-ebook {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 4rem;
        align-content: center;
        align-items: flex-end;
    }

    .bookshelf-content-video {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 4rem;
        align-content: center;
        align-items: flex-end;
    }

    .bookshelf-content-podcast {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 4rem;
        align-content: center;
        align-items: flex-end;
    }

    .reading-time {
        display: flex;
        align-items: center;

        @media (max-width: 576.98px) {
            flex-direction: column;
            align-items: flex-start;

            p {
                text-align: left !important;
                margin-left: 0;
            }
        }
    }

    .loading {
        img {
            opacity: 0.5;
        }
    }

    .reading-swiper {
        margin-bottom: 2rem;

        .swiper-wrapper {
            align-items: flex-end;
        }
        .reading-now-swiper {
            justify-content: flex-end;
        }
    }

    .ebook {
        width: 180px;
        .bookshelf-collection {
            .cover {
                height: calc(100% - (16px * 5));
                width: 100%;
            }
        }
    }

    .loading-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .content-loading {
            font-size: 48px;
            color: var(--primary);
        }
    }

    .goal-button {
        margin-left: 3rem;
        margin-bottom: 0;
        color: #0066ff;
        cursor: pointer;
        font-family: 'Prompt' !important;
    }

    .podcast {
        width: 250px;
    }

    .bookSwiper .swiper-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .bookSwiper .swiper-slide > div .title {
        width: 95%;
    }

    .right {
        text-align: right;
    }

    .align-self-center {
        align-self: center;
    }

    .badge-sample {
        position: absolute;
        bottom: 0px;
    }

    .shelf-item {
        align-content: flex-end;
        margin-right: 16px;
        @include setHeightBookshelf(1280px, 8);
        width: calc((100% - (#{$space} * 6)) / 7);
        > div {
            margin: 0;
        }
    }

    .ant-col {
        button {
            height: 32px;
            padding: 0;
            line-height: 30px;
            width: 120px;
            font-family: 'Prompt', tahoma, sans-serif;
            font-weight: 400 !important;
        }
    }

    h1 {
        margin-bottom: 16px;
        color: $onsurface-color;
        text-align: left;
    }

    .view {
        display: flex;
        justify-content: left;
    }

    .image-view {
        width: 100%;
    }

    .image-view.default {
        cursor: default;
    }

    .image-view.pointer {
        cursor: pointer;
    }

    .image-view.loadingReader {
        pointer-events: none;
        cursor: not-allowed !important;
    }

    .dot {
        display: inline;
        margin-left: 6px;
        color: var(--grey2);
        cursor: pointer;

        @media (max-width: 576.98px) {
            margin-left: 0;
        }
    }

    .title {
        margin-top: 6px;
        text-align: right;
        font-weight: 400 !important;
        font-size: 14px;
        color: $onsurface-color;
        line-height: 1.2em;
        margin-bottom: 2px;
        white-space: nowrap;
    }

    .grey-dark,
    .grey-light {
        font-family: 'Prompt', tahoma, sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .grey-dark {
        color: #4f4f4f;
    }

    .grey-light {
        color: #828282;
    }

    .blue {
        cursor: pointer;
        color: $onsurface-color;
    }
}

.bookShelfNew {
    max-width: 1280px;
    margin: 0 auto;

    h1 {
        font-size: 1.625rem;
        margin-top: 3.5rem;
        margin-bottom: 2rem;
        text-align: center;
    }
}
