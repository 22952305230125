.openAppTab {
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: clamp(64px, 1.5vw, 72px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    padding: 0 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: all 0.5s;
    background-color: var(--surface2);

    &.fadeOut {
        transform: translateY(4.5rem);
        opacity: 0;
    }

    .appTitleContainer {
        display: flex;
        align-items: center;
        gap: 1rem;

        .appIcon {
            width: 40px;
            height: 40px;
            filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
        }

        p.appName {
            color: var(--grey1);
            font-size: clamp(12px, 1.5vw, 16px);
            font-weight: 600;
            margin-bottom: 0;
            line-height: 1.6;
        }

        p.description {
            font-size: clamp(10px, 1.5vw, 14px);
            color: var(--grey1);
            margin-bottom: 0;
        }
    }

    a {
        color: #fff;
        background: #63a3fd;
        border: none;

        &:hover,
        &:focus {
            background: var(--secondary);
            color: #fff;
        }
    }
}
