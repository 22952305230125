@import '/public/style/base';

.navbar {
    position: fixed;
    width: 100%;
    height: $header-height;
    z-index: 98;
    top: 0;
    background-color: var(--primary-dark);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    transition: all 0.5s;

    @media (max-width: 768.98px) {
        height: $headerSmall-height;
        flex-direction: column;
    }

    @media (max-width: 576.98px) {
        padding: 0.5rem;
    }

    .flexContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;
        color: var(--on-primary);

        .username {
            font-size: clamp(0.675rem, 2vw, 0.875rem);
            display: -webkit-box;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .hamburger-button {
            right: 0px;
            top: 38.5px;
            width: clamp(24px, 2vw, 32px);
            cursor: pointer;
            color: var(--on-primary);
        }

        .themeBtn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            align-content: space-around;
            gap: 1rem;
            margin-top: -8px;

            span {
                width: max-content;
                color: var(--on-primary);
            }

            .ant-switch-checked {
                background-color: var(--secondary);
            }
        }
    }

    .nav-inner-mobile,
    .navMiddle {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        @media (max-width: 768.98px) {
            display: none;
        }

        .search {
            .nav-search-autocomplete {
                width: 100%;

                .ant-select-dropdown {
                    position: fixed !important;
                }

                input {
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    font-family: Prompt;
                    padding: 0px 3px;

                    &:focus {
                        border: 0px;
                    }
                }
                .ant-input-affix-wrapper {
                    border-color: transparent;
                    box-shadow: none;
                    border-top-left-radius: 20px !important;
                    border-bottom-left-radius: 20px !important;
                }

                .ant-input-group-addon,
                .ant-input-group-addon > button {
                    border-top-right-radius: 20px !important;
                    border-bottom-right-radius: 20px !important;
                    border: 0 !important ;
                }
            }
        }

        .tabMenu {
            display: flex;
            justify-content: center;
            width: 100%;

            .ant-tabs-tab {
                padding: 0;
                color: var(--on-primary);
            }

            .ant-tabs-nav-operations {
                display: none !important;
            }

            .ant-tabs-top > .ant-tabs-nav {
                margin: 0;

                .ant-tabs-ink-bar {
                    display: none;
                }

                .ant-tabs-nav-wrap {
                    justify-content: space-between;
                }

                &::before {
                    border: none;
                }
            }

            .ant-tabs-tab + .ant-tabs-tab {
                @media (max-width: 900.98px) {
                    margin: 0 0 0 1rem;
                }
            }

            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: var(--on-primary);
            }

            .ant-tabs-tab-btn {
                &:hover,
                &:focus {
                    color: var(--secondary);
                }
            }
        }
    }

    .nav-inner-mobile {
        display: none;
        width: 100%;
        max-width: none;

        @media (max-width: 768.98px) {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .search {
                width: 100%;
            }
        }
    }

    .lang-box {
        cursor: default;
        font-size: clamp(0.775rem, 2vw, 0.875rem);
        white-space: nowrap;

        .selected-lang {
            font-weight: 600;
            cursor: pointer;
        }

        .unselected-lang {
            opacity: 0.8;
            font-weight: 400;
            cursor: pointer;
        }
    }

    .login-button {
        @include setHeaderBold(500);
        right: 0px;
        top: 32.5px;
        outline: none;
        width: 100%;
        max-width: 150px;
        height: 2.5rem;
        background-color: var(--surface);
        border: none;
        border-radius: 20px;
        color: var(--hti-primary-light);
        font-size: 16px;
        cursor: pointer;
        margin-top: 5px;
        transition: all 0.3s;

        @media (max-width: 576.98px) {
            height: 2rem;
            font-size: 0.875rem;
            max-width: 100px;
        }
    }

    .userInfo {
        display: flex;
        align-items: center;

        .logo {
            display: flex;
            align-items: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: all 0.3s;

            &.cursor:hover {
                cursor: pointer;
                transform: scale(1.05);
            }

            @media (max-width: 768.98px) {
                width: 48px;
                height: 48px;
            }
        }

        .div-text {
            font-family: Prompt !important;
            margin-left: 0.5rem;
            display: inline-block;
            vertical-align: middle;

            .top-text {
                font-weight: 500;
                font-size: clamp(0.875rem, 2vw, 1rem);
                text-align: left;
                line-height: 1.6;
                margin-bottom: 0;
                color: var(--on-primary);
                padding: 0;
            }

            .under-text {
                font-weight: 400;
                font-size: clamp(0.75rem, 2vw, 0.875rem);
                line-height: 1.6;
                overflow: hidden;
                color: var(--on-primary);
                margin-bottom: 0;

                @media (max-width: 1024.98px) {
                    max-width: 80%;
                }

                @media (max-width: 768.98px) {
                    max-width: 100%;
                }
            }
        }

        .text-overflow-one-line {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .text-overflow-two-line {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
