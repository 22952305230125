.ant-empty-description {
    color: var(--on-surface);
}

.antDropDownNav {
    .ant-dropdown-menu {
        background-color: var(--modal-background);
    }

    .dropdown-item {
        color: var(--grey1);

        svg {
            color: var(--hti-primary-light);

            #secondary {
                color: var(--hti-secondary);
            }
        }

        &:hover {
            color: #000;

            svg {
                color: var(--primary);

                #secondary {
                    color: var(--secondary);
                }
            }
        }

        .ant-dropdown-menu-title-content {
            display: flex !important;
            justify-content: flex-start;
            align-items: center;
            min-width: 140px;
        }

        span {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.ant-back-top {
    right: 3rem;
    bottom: 3rem;

    @media (max-width: 576.98px) {
        right: 1rem;
        bottom: 1rem;
    }
}

.ant-modal {
    @media (max-width: 767px) {
        max-width: calc(100% - 1rem);
    }
}

.ant-modal-content {
    border-radius: 10px !important;
}

.ant-modal-confirm-btns {
    float: unset !important;
    text-align: center;
}

.ant-modal-close-x {
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important;
    transform: translate(-6px, 10px);
}

.ant-modal-confirm .ant-modal-body {
    @media (max-width: 576.98px) {
        padding: 1rem !important;
    }
}
