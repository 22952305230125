button.rent,
button.Rent {
  background-color: var(--primary);
  color: var(--on-primary-dark);
  border-color: var(--primary);
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: var(--primary);
    color: var(--on-primary-dark);
    border-color: var(--primary);
    opacity: 0.8;
  }
}

button.rented {
  color: #737373 !important;
  box-shadow: none;
}

button.waitingList {
  background-color: var(--secondary);
  color: var(--on-secondary);
  border-color: var(--secondary);
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: var(--secondary);
    color: var(--on-secondary);
    border-color: var(--secondary);
    opacity: 0.8;
  }
}

button.waitingListed {
  background-color: var(--secondary-variant);
  color: var(--on-secondary);
  border-color: var(--secondary-variant);
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: var(--secondary-variant);
    color: var(--on-secondary);
    border-color: var(--secondary-variant);
    opacity: 0.8;
  }
}
