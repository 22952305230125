.qNh {
    min-height: calc(100vh - 105px);
    min-width: 375px;

    @media (max-width: 576.98px) {
        padding: 2rem 1rem;
        padding-bottom: 50px;
    }

    h1 {
        margin: 0 0 4rem 0;

        @media (max-width: 576.98px) {
            margin: 0 0 2rem 0;
            padding: 0;
        }
    }

    .bookSwiper {
        flex: 2;

        @media (max-width: 1312px) {
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 2rem 1rem;

        .contentCover {
            display: flex;
            gap: 1rem;

            img {
                width: 150px;
            }

            .title {
                width: fit-content;
                font-size: clamp(0.75rem, 0.7197rem + 0.1294vw, 0.875rem);
                font-weight: 500;
                margin-bottom: 0;
                color: var(--header);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                cursor: pointer;
                line-height: 1.6;
            }

            .subtitle {
                width: fit-content;
                font-size: clamp(0.625rem, 0.5947rem + 0.1294vw, 0.75rem);
                font-weight: 300;
                margin-bottom: 0;
                color: var(--sub-header);
                line-height: 1.6;
            }

            .cursor-not-allowed {
                cursor: not-allowed;
            }

            .expire-tag {
                border: none;
                border-radius: 8px;
                padding-inline: 8px;
                margin: 5px 0px;
            }

            .bookDetail {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 0.5rem;
                width: 100%;

                button {
                    background-color: var(--secondary-variant) !important;
                    border: none;
                }

                button.stat {
                    color: var(--secondary-variant);
                    background-color: transparent !important;
                    border: 1px solid var(--secondary-variant);

                    &:hover {
                        border: 1px solid var(--secondary-variant);
                        color: var(--secondary-variant);
                    }
                }

                .bookTitleContainer {
                    margin-top: 0;
                }
            }
        }
    }

    .nodata {
        height: calc(
            100vh - #{$header-height} - #{$footer-height} - #{$bcwithmargin-height} - #{$headtext-height} +
                16px
        );

        @media (max-width: 600px) {
            height: calc(
                100vh - #{$headerSmall-height} - #{$footerSmall-height} - #{$bcwithmargin-height} + #{$headtext-height} -
                    16px
            );
        }
    }

    .subtitle {
        @include setHeaderBold(500);
        color: $primary-color;
        font-size: 14px;
        line-height: 1.2em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .subtitle-detail {
        @include setHeaderBold(500);
        color: $secondary-color;
        font-size: 14px;
    }

    .headDate {
        @include setHeaderBold(400);
        color: $grey3-color;
        font-size: 12px;
    }

    .date {
        @include setHeaderBold(400);
        color: $grey4-color;
        font-size: 11px;
        margin-bottom: 0;

        &.Rented {
            color: $secondary-color;
        }
    }

    .ant-btn {
        width: fit-content;

        &.returned {
            display: none;
        }

        &.rented {
            color: $onbutton-color !important;
            background-color: $button-color !important;
        }
    }
}
