.view-all-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .viewAllTitle {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .content-list {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 1rem;
        padding-bottom: 2rem;
    }

    .content-list-container-ebook {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 1rem;
        align-items: flex-end;
        height: fit-content;

        @media (max-width: 1024.98px) {
            grid-template-columns: repeat(5, 1fr);
        }

        @media (max-width: 768.98px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 576.98px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .content-list-container-video {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        align-items: flex-end;

        @media (max-width: 768.98px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 576.98px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .content-list-container-podcast {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;

        @media (max-width: 768.98px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 576.98px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .list-row {
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-end;
    }
}

.content-list {
    // flex: 2;

    &.list-row-categories {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        gap: 1rem;

        @media (max-width: 576.98px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .category {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        height: 100%;

        .cover {
            overflow: hidden;
            border-radius: 0.5rem;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }
}

.segment-item {
    .segment-categories {
        align-items: flex-end;
    }
}

.rentableContentContainer {
    width: 100vw;
    margin: 0 auto;
    max-width: 1280px;
    min-height: calc(100vh - 150px);
    position: relative;

    .contentContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        align-items: flex-end;
        gap: 1rem;
    }
}
